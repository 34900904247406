
import { DB_ICON } from "../01_APPDATA/DB_ICON";
import { ENU_ICON_TYPE, I_DATE_OBJ, I_ICON_ITEM, I_KEY_VALUE_DATA, I_RESULT } from "../02_Interfaces/IGLOBAL";
import { ENU_TYPE_NOTE, I_GROUPE_NOTE_ITEM, I_NOTE_ITEM } from "../02_Interfaces/INOTE";
import { DB_MONTH_NAME, NOTE_COMPTA_EMPTY_TEMPLATE, NOTE_CUST_FORM_EMPTY_TEMPLATE } from "../01_APPDATA/DB_APP";
import { I_SHARED_ITEM } from "../02_Interfaces/ISHARER";
import { ENU_ACCOUNT_TYPE, ENU_LINE_TYPE, I_COMPTA_ACCOUNT_ITEM, I_COMPTA_FORM_ITEM, I_COMPTA_FORM_NOTE } from "../02_Interfaces/I_COMPTA_FORM";
import { I_CUST_FORM, I_CUST_FORM_ITEM } from "../02_Interfaces/I_CUST_FORM";
import { domainToUnicode } from "url";
import { ORDO_PROCESS_ITEM } from "./WASPORDO";
const CryptoJS = require('crypto-js');


//Constantes globales
export const CupBoardCode: string = "[WCB.]";
export const CustFormCode: string = "[CFB.]";
export const CustFormChildCode: string = "WASP-CUST-FORM";
//-------------------

//Variables globales
export var COOKIEFUNCTION: I_KEY_VALUE_DATA = {
    key: "COOKIE",
    value: false,
    data: ""
};
export var COOKIEOPTIONAL: I_KEY_VALUE_DATA = {
    key: "OPTIONALCOOKIE",
    value: false,
    data: ""
};

//-------------------
export const DB_EMOJI: string = "�☺️🙂😊😀😁😃😄😎😆😂☹️🙁😞😟😣😖😢😭🥲🥹😂😠😡😨😧😦😱😫😩😮😯😲😺😸🐱😼😗😙😚😘😍😉😜😘😛😝😜🤑🫤🤔😕😟😐😑😳😞😖🤐😶😇👼😈😎😪😏😒😵😵‍💫😕🤕🤒😷🤢🤨😬☠️💀🏴‍☠️🐔🐓"

export const GetDateObjectFromString = (dateString: string) => {
    // console.log(dateString);
    const tmpDateSplited = dateString.split(" ")[0].split("/");
    const tmpTimeSplited = dateString.split(" ").length > 1 ? dateString.split(" ")[1].split(":") : ["00", "00"];
    let finaleDateObj: I_DATE_OBJ = {
        date: tmpDateSplited[0].replace(" ", "").length === 0 ? "00" : tmpDateSplited[0].replace(" ", ""),
        month: tmpDateSplited[1].replace(" ", ""),
        year: tmpDateSplited[2].replace(" ", ""),
        hour: tmpTimeSplited[0],
        min: tmpTimeSplited[1],
        dateString: tmpDateSplited[0] + " " + GetMonStringFromItNumber(Number.parseInt(tmpDateSplited[1])) + " " + tmpDateSplited[2],
        hourString: tmpTimeSplited[0] + ":" + tmpTimeSplited[1],
        dateObj: new Date(parseInt(tmpDateSplited[2]), (parseInt(tmpDateSplited[1]) - 1), parseInt(tmpDateSplited[0]), parseInt(tmpTimeSplited[0]), parseInt(tmpTimeSplited[1]))
    }

    return finaleDateObj;
}

export const GetDateStringFromObject = (date: Date, getWithMinutes: boolean) => {
    if (getWithMinutes)
        return (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + "/" + ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)) + "/" + date.getFullYear() +
            " " + date.getHours() + ":" + date.getMinutes()
    else
        return (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + "/" + ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)) + "/" + date.getFullYear()
}

export const GetMonStringFromItNumber = (monthId: number) => {
    let tmpMonString: string = DB_MONTH_NAME[monthId - 1];//Tableau commençant par 0
    return tmpMonString;
}

export const typeNoteIdFromString = (typeString: string) => {
    for (const item in ENU_TYPE_NOTE) {
        if (!isNaN(parseInt(item))) {
            if (ENU_TYPE_NOTE[parseInt(item)] === typeString) {
                return parseInt(item);
            }
        }
    }
    return -1;
}

export const GetIconFromType = (typeIcon: ENU_ICON_TYPE, size: number, color?: string) => {
    let tmpIconItem: I_ICON_ITEM = DB_ICON.filter(i => i.iconType === typeIcon)[0];
    let tmpIcon: string = "";

    if (tmpIconItem !== undefined) {
        tmpIcon = tmpIconItem.icon;
        //Gestion des proportions
        tmpIcon = tmpIcon.split('height="16" width="16"').join('height="' + size + 'px" width="' + size + 'px"');
        tmpIcon = tmpIcon.split('viewBox="0 0 16 16"').join('viewBox="0 0 ' + size + ' ' + size + '"');
        tmpIcon = tmpIcon.split('scale(' + tmpIconItem.scalValue + ',' + tmpIconItem.scalValue + ')').join('scale(' + ((tmpIconItem.scalValue * size) / 16) + ',' + ((tmpIconItem.scalValue * size) / 16) + ')');
        tmpIcon = tmpIcon.split('scale(-' + tmpIconItem.scalValue + ',' + tmpIconItem.scalValue + ')').join('scale(-' + ((tmpIconItem.scalValue * size) / 16) + ',' + ((tmpIconItem.scalValue * size) / 16) + ')');
        tmpIcon = tmpIcon.split('scale(-' + tmpIconItem.scalValue + ',-' + tmpIconItem.scalValue + ')').join('scale(-' + ((tmpIconItem.scalValue * size) / 16) + ',-' + ((tmpIconItem.scalValue * size) / 16) + ')');
        tmpIcon = tmpIcon.split('scale(' + tmpIconItem.scalValue + ',-' + tmpIconItem.scalValue + ')').join('scale(' + ((tmpIconItem.scalValue * size) / 16) + ',-' + ((tmpIconItem.scalValue * size) / 16) + ')');
        //---------------------

        // changement de la couleur
        if (color !== undefined)
            tmpIcon = tmpIcon.split('fill="#3C3C3C"').join('fill="#' + color as string + '"');
    }


    return tmpIcon;
}

export const GetFormatedDate = (date: Date, showTime?: Boolean) => {
    let tmpFinalDate: string = "";

    tmpFinalDate = (date.getDate() > 9 ? date.getDate() + "" : "0" + date.getDate());
    tmpFinalDate += "/" + ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1));
    tmpFinalDate += "/" + date.getFullYear();

    if (showTime) {
        tmpFinalDate += " " + (date.getHours() > 9 ? date.getHours() : "0" + date.getHours()) + ":" + (date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes())
    }

    return tmpFinalDate;
}

export const convertStringToBuffer = (str: string) => {

    var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    var bufView = new Uint16Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }

    return buf;
}

//Mais en fait 32 :)
export const decodeUtf8 = (arrayBuffer: ArrayBufferLike, Filetype?: string) => {



    var result = "";
    var i = 0;
    var c = 0;
    var c3 = 0;
    var c2 = 0;

    var data = new Uint32Array(arrayBuffer);

    // If we have a BOM skip it
    if (data.length >= 3 && data[0] === 0xef && data[1] === 0xbb && data[2] === 0xbf) {
        i = 3;
    }

    while (i < data.length) {
        c = data[i];

        if (c < 128) {
            result += String.fromCharCode(c);
            i++;
        } else if (c > 191 && c < 224) {
            // if (i + 1 >= data.length) {
            //     throw "UTF-8 Decode failed. Two byte character was truncated.";
            // }
            c2 = data[i + 1];
            result += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
        } else {
            // if (i + 2 >= data.length) {
            //     throw "UTF-8 Decode failed. Multi byte character was truncated.";
            // }
            c2 = data[i + 1];
            c3 = data[i + 2];
            result += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
            i += 3;
        }
    }

    //Convertions complmémentaires :
    result = result.split("鰡").join("épa");
    result = result.split("裥").join("èce");
    result = result.split("顴").join("éat");
    result = result.split("飬").join("écl");
    result = result.split("頺").join("ant");
    result = result.split("鲩").join("éri");
    result = result.split("鲩").join("éri");
    result = result.split("頰").join("é p");
    result = result.split("頠").join("ées");
    result = result.split("頠").join("ées");
    result = result.split("ߑ�").join("...")

    //Ajout du type de data approprié
    const mimType = Filetype === 'pdf' ? 'application/pdf' : Filetype === 'xlsx' ? 'application/xlsx' :
        Filetype === 'pptx' ? 'application/pptx' : Filetype === 'csv' ? 'application/csv' : Filetype === 'docx' ? 'application/docx' :
            Filetype === 'jpg' ? 'application/jpg' : Filetype === 'png' ? 'application/png' : '';

    return Filetype !== undefined ? `data:${mimType};base64,` + result : result;
}

export const GetHtmlFromFileContent = (content: string) => {
    var objbuilder = '';
    objbuilder += ('<object width="100%" height="100%" data = "data:application/pdf;base64,');
    objbuilder += (content);
    objbuilder += ('" type="application/pdf" class="internal">');
    return objbuilder;
}

export const arrayBufferToBase64 = (Arraybuffer: ArrayBuffer, Filetype: string) => {

    let binary = '';
    const bytes = new Uint8Array(Arraybuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    const file = window.btoa(binary);
    const mimType = Filetype === 'pdf' ? 'application/pdf' : Filetype === 'xlsx' ? 'application/xlsx' :
        Filetype === 'pptx' ? 'application/pptx' : Filetype === 'csv' ? 'application/csv' : Filetype === 'docx' ? 'application/docx' :
            Filetype === 'jpg' ? 'application/jpg' : Filetype === 'png' ? 'application/png' : '';
    const url = `data:${mimType};base64,` + file;

    console.log(Arraybuffer);
    console.log(url);

    return url;
}

export const GoToElement = async (idName: string) => {
    window.location.hash = "#" + idName;
    // window.location.hash = "#"+"";
    setTimeout(async () => {
        window.location.hash = "";
    }, 3000)
}

export const SetCookie = (cname: string, cvalue: string, exdays: number) => {
    const d: Date = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires: string = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const GetCookie = (cname: string) => {
    try {
        let name: string = cname + "=";
        let decodedCookie: string = decodeURIComponent(document.cookie);
        let ca: string[] = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c: string = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
    } catch (err) {
        alert("Oups!...une erreur empèche le chargement des cookies.");
        SetCookie(cname, "", 1);
    }

    return "";
}

export const noteEmptyStringInfo: string = "...";

export const encrypt = (text: string) => {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

export const GetDistinctSharedItems = (sharedItems: I_SHARED_ITEM[]) => {
    let finalSharedList: I_SHARED_ITEM[] = [];
    for (let x = 0; x < sharedItems.length; x++) {
        if (finalSharedList.filter(si =>
            (si.idContact === sharedItems[x].idContact && si.idGroup === sharedItems[x].idGroup && si.idNote === sharedItems[x].idNote && si.idType === sharedItems[x].idType) ||
            (si.idContact === sharedItems[x].idContact && si.idGroup === sharedItems[x].idGroup && si.idNote === null && si.idType === sharedItems[x].idType) ||
            (si.idContact === sharedItems[x].idContact && si.idGroup === sharedItems[x].idGroup && si.idNote === null && si.idType === null)).length === 0) {
            finalSharedList.push(sharedItems[x]);
        }
    }
    return finalSharedList;
}

export const GetDistinctListOfString = (dataString: string[]) => {
    let finalDistinctList: string[] = [];


    for (let x = 0; x < dataString.length; x++) {
        if (finalDistinctList.filter(_s => _s === dataString[x]).length === 0) {
            finalDistinctList.push(dataString[x]);
        }
    }


    return finalDistinctList;
}

export const ConvertVideoURL = (htmlContain: string, videoOnly?: boolean) => {
    let allString = htmlContain.matchAll(/VID:(.*?);/g);
    let tmpURLPart = Array.from(allString);
    let finalString: string = videoOnly !== undefined && videoOnly ? "" : htmlContain;

    let videoHtmlTemplate: string = `<div class='VIDEO_BOX'><div><iframe src=[VIDURL] width="420" height="345" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe><div>
                                        <div class='VIDEO_URL_ZONE'>
                                        <span class='VIDEO_LABEL_URL'>Accès direct :</span> <span><a href='[VIDURL]' target='_blank'>[VIDURL]</a></span>
                                        </div>
                                    <div>`;

    for (let x = 0; x < tmpURLPart.length; x++) {
        let tmpURL: string = tmpURLPart[x]?.[0];
        let tmpSubString = Array.from(tmpURL.matchAll(/href="(.*?)"/g))[0];

        if (tmpSubString?.[1] !== undefined) {
            let finalURL: string = (tmpSubString?.[1]).replace("/watch?v=", "/embed/");
            finalString = finalString.split(tmpURLPart[x]?.[0]).join(videoHtmlTemplate.split("[VIDURL]").join(finalURL));
        }

    }

    return finalString;
}

export const extractDistinctGroupsFromSelectedNotes = (notes: I_NOTE_ITEM[], groups: I_GROUPE_NOTE_ITEM[]) => {
    let tmpGroups: I_GROUPE_NOTE_ITEM[] = [];


    (notes).forEach(n => {
        if (groups.filter(g => g.id === n.idgroupnote).length > 0)
            if (tmpGroups.filter(g => n.idgroupnote === g.id).length === 0) {
                tmpGroups.push({
                    id: n.idgroupnote,
                    title: groups.filter(g => g.id === n.idgroupnote)[0].title,
                    memory: "",
                    isAuthor: 0,
                    authorLastname: "",
                    authorName: "",
                })
            }
    });

    return tmpGroups;
}

export const IsComptaForm = (description: any) => {
    if (((description as string) + "").length > 0) {
        description = (description === undefined ? "" : (description as string) + "");
        description = description.split("&amp;").join("&&");
        if (description.indexOf(NOTE_COMPTA_EMPTY_TEMPLATE) > -1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }

}

export const IsCustForm = (description: string) => {
    description = ((description as any) as string) + "";
    description = description.split("&amp;").join("&&");
    if (description.indexOf("WASP-CUST-FORM") > -1) {
        return true;
    } else {
        return false;
    }
}

export const GetNoteCustParentIdFromTitle = (title: string) => {
    let _allFORMLine;
    try {
        _allFORMLine = (title as string).matchAll(/WASP-CUST-FORM-PARENT-\[(.*?)\]/g);
    } catch (error) {
        _allFORMLine = ("").matchAll(/WASP-CUST-FORM-PARENT-\[(.*?)\]/g);
    }

    let allFORMLine = Array.from(_allFORMLine);

    return allFORMLine.length > 0 ? allFORMLine[0][1] : ""
}

export const GetNoteCustFormData = (noteDescription: string, noteId: number) => {
    //Echangtillon des données formulaire
    //===================================
    // &&WASP-CUST-FORM&&
    // &&FORM:1;F_TXT;Un champ de saisie;Un champ plutot cool à voir et ça c'est vraiment sympath....;1;;;&&
    // &&FORM:11;F_NUM;Un champ pour les nombre;Un champ ou tu ne peux sasir que des nombre;0;;;&&
    // &&FORM:2;F_ML_TXT;Description;Une description pour que tu peux remplir;0;;;;&&
    // &&FORM:3;F_CHOICE;Un champ de choix;Un champ où tu peux sélectionner une valeur;1;Valeur 01|Valeur 02|Valeur 03|;Valeur 01&&
    // &&FORM:4;F_BOOLEAN;Choisi le statut;Un champs de sélection 'Vrai' / 'Faux';0;;1&&
    // &&DATA:1;Une informations à retenir;&&
    // &&DATA:2;C'est le moment d'avoir une zone de saisie sympath et peut etre en HTML.;&&
    // &&DATA:3;Valeur 02;&&
    // &&DATA:4;1;&&
    // &&CONFIG:4;1;&&

    //Recherche des donées de la note
    //-------------------------------
    let _allFORMLine;
    try {
        _allFORMLine = noteDescription !== null ? (noteDescription as string).matchAll(/&amp;&amp;FORM:(.*?)&amp;&amp;/g) : ("").matchAll(/&amp;&amp;FORM:(.*?)&amp;&amp;/g);
    } catch (error) {
        _allFORMLine = ("").matchAll(/&amp;&amp;FORM:(.*?)&amp;&amp;/g);
    }

    let allFORMLine = Array.from(_allFORMLine);
    let tmpFinalData: I_CUST_FORM_ITEM[] = [];



    for (let x = 0; x < allFORMLine.length; x++) {
        if (allFORMLine[x][0].indexOf("FORM:") > -1) {
            let tmpBrutLine: string[] = allFORMLine[x][1].split(";");
            tmpFinalData.push({
                id: tmpBrutLine[0],
                type: tmpBrutLine[1],
                title: tmpBrutLine[2],
                description: tmpBrutLine[3].length > 0 ? tmpBrutLine[3] : undefined,
                required: tmpBrutLine[4] === "1" ? true : false,
                allProposals: tmpBrutLine[5].split("|"),
                defaultValue: tmpBrutLine[6]
            })
        }
    }

    try {
        _allFORMLine = noteDescription.matchAll(/&amp;&amp;DATA:(.*?)&amp;&amp;/g);
    } catch (err) {
        _allFORMLine = ("").matchAll(/&amp;&amp;DATA:(.*?)&amp;&amp;/g);
    }

    allFORMLine = Array.from(_allFORMLine);

    for (let x = 0; x < allFORMLine.length; x++) {
        if (allFORMLine[x][0].indexOf("DATA:") > -1) {
            let tmpBrutLine: string[] = allFORMLine[x][1].split(";");
            if (tmpFinalData.filter(data => data.id === tmpBrutLine[0]).length > 0) {
                tmpFinalData.filter(data => data.id === tmpBrutLine[0])[0].value = tmpBrutLine[1]
            }
        }
    }

    try {
        _allFORMLine = noteDescription.matchAll(/&amp;&amp;CONFIG:(.*?)&amp;&amp;/g);
    } catch (err) {
        _allFORMLine = ("").matchAll(/&amp;&amp;CONFIG:(.*?)&amp;&amp;/g);
    }

    allFORMLine = Array.from(_allFORMLine);

    for (let x = 0; x < allFORMLine.length; x++) {
        if (allFORMLine[x][0].indexOf("CONFIG:") > -1) {
            let tmpBrutLine: string[] = allFORMLine[x][1].split(";");
            if (tmpFinalData.filter(data => data.id === tmpBrutLine[0]).length > 0) {
                tmpFinalData.filter(data => data.id === tmpBrutLine[0])[0]._config_value = tmpBrutLine[1];
                tmpFinalData.filter(data => data.id === tmpBrutLine[0])[0]._config_id_note = tmpBrutLine[2]
            }
        }
    }

    try {
        _allFORMLine = noteDescription.matchAll(/&amp;&amp;FORMID:(.*?)&amp;&amp;/g);
    } catch (err) {
        _allFORMLine = ("").matchAll(/&amp;&amp;FORMID:(.*?)&amp;&amp;/g);
    }
    allFORMLine = Array.from(_allFORMLine);




    return {
        id: allFORMLine.length > 0 ? parseInt(allFORMLine[0][1]) : -1,
        parent: allFORMLine.length > 0 ? parseInt(allFORMLine[0][1]) === noteId ? true : false : false,
        lines: tmpFinalData,
        formatedHtml: ""
    } as I_CUST_FORM;
}

export const getCustFormNoteHTML = (noteDescription: string, noteId: number) => {
    console.log(noteDescription);
    let tmpFormObj: I_CUST_FORM = GetNoteCustFormData(noteDescription, noteId);

    let finalString: string = NOTE_CUST_FORM_EMPTY_TEMPLATE.replace("PARENTNOTEID", tmpFormObj.id.toString());

    const EmptyFieldTemplate: string = "<p>&amp;&amp;FORM:[IDFIELD];[FIELDTYPE];[FIELDTITLE];[FIELDDESCRIPTION];[REQUIRED];[VALUES];[DEFAULTVALUE];&amp;&amp;</p>";
    const EmptyFieldDataTemplate: string = "<p>&amp;&amp;DATA:[IDFIELD];[VALUE];&amp;&amp;</p>";
    const EmptyFieldConfigTemplate: string = "<p>&amp;&amp;CONFIG:[IDFIELD];[VALUE_CONFIG];[ID_NOTE_ITEM];&amp;&amp;</p>";

    //Ajout des champs et des données
    (tmpFormObj.lines).forEach(line => {
        // console.log("ici ça marche aussi");
        finalString = finalString + "\n" + EmptyFieldTemplate
            .replace("[IDFIELD]", line.id)
            .replace("[FIELDTYPE]", line.type)
            .replace("[FIELDTITLE]", line.title)
            .replace("[FIELDDESCRIPTION]", line.description !== undefined ? line.description : "")
            .replace("[REQUIRED]", line.required ? "1" : "0")
            .replace("[VALUES]", line.allProposals !== undefined ? line.allProposals.filter(p => p.length > 0).map(p => { return p }).toString().split(",").join("|") : "")
            .replace("[DEFAULTVALUE]", line.defaultValue !== undefined ? line.defaultValue : "");

        finalString = finalString + "\n" + EmptyFieldDataTemplate
            .replace("[IDFIELD]", line.id)
            .replace("[VALUE]", line.value !== undefined ? line.value.toString() : "");

        finalString = finalString + "\n" + EmptyFieldConfigTemplate
            .replace("[IDFIELD]", line.id)
            .replace("[VALUE_CONFIG]", line._config_value !== undefined ? line._config_value.toString() !== "" && line._config_value.toString() !== "undefined" ? line._config_value.toString() : "0" : "0")
            .replace("[ID_NOTE_ITEM]", line._config_id_note !== undefined ? line._config_id_note.toString() : "");

    });

    return finalString;
}

export const GetNoteComptaData = (noteDescription: string) => {
    //Echantillon de données comptables
    //=================================
    // &&WASP-CMTA-FORM&&
    // &&MONTH:3&&
    // &&YEAR:2023&&
    // &&ACCOUNT:1;Didier;usual;4500&&
    // &&ACCOUNT:2;Natacha;usual;4500&&
    // &&ACCOUNT:3;Didier;saved;100000&&
    // &&ACCOUNT:4;Natacha;saved;100000&&
    // &&ACCOUNT:6;Stella;saved;50000&&
    // &&ACCOUNT:7;Willima;saved;50000&&
    // &&DATA:1;facturation;2000;20;Facturation de Décembre 2022;1;1&&
    // &&DATA:2;food;25;0;Repas;1;1&&
    // &&DATA:3;food;75;0;Resto;1;2&&
    //==================================
    // noteDescription = noteDescription.split("&amp;").join("&&");




    let _month = noteDescription.matchAll(/&amp;&amp;MONTH:(.*?)&amp;&amp;/g);
    let month: number = 0;
    let arrayResult = Array.from(_month);

    //Recherche du mois
    //--------------------
    if (arrayResult.length > 0) {
        month = parseInt(arrayResult[0][1]);
    }


    let _year = noteDescription.matchAll(/&amp;&amp;YEAR:(.*?)&amp;&amp;/g);
    let year: number = 0;
    arrayResult = Array.from(_year);

    //Recherche de l'année
    //--------------------
    if (arrayResult.length > 0) {
        year = parseInt(arrayResult[0][1]);
    }


    //Préparation de l'objet à retourner
    //----------------------------------
    let allFinalLine: I_COMPTA_FORM_ITEM[] = [];
    let allFinalAccount: I_COMPTA_ACCOUNT_ITEM[] = [];
    //----------------------------------

    //Recherche des donées de la note
    //-------------------------------
    let _allLine = noteDescription.matchAll(/&amp;&amp;DATA:(.*?)&amp;&amp;/g);
    let allLine = Array.from(_allLine);

    if (allLine.length > 0) {

        for (let x = 0; x < allLine.length; x++) {
            const tmpLine: string = allLine[x][1];
            const tmpSplitedLine: string[] = tmpLine.split(";")

            allFinalLine.push({
                idLigne: tmpSplitedLine[0],
                lineType: tmpSplitedLine[1] as ENU_LINE_TYPE,
                amount: parseFloat(tmpSplitedLine[2]),
                taxeRatio: parseFloat(tmpSplitedLine[3]),
                description: tmpSplitedLine[4],
                //Si les sources et les destinations sont renseignés
                source: tmpSplitedLine[5],
                destination: tmpSplitedLine[6]
                //----------------------------------------------------
            })

        }
    }

    //Recherche des comptes utilisateurs
    //----------------------------------
    let _allAccount = noteDescription.matchAll(/&amp;&amp;ACCOUNT:(.*?)&amp;&amp;/g);
    let allAccount = Array.from(_allAccount);

    if (allAccount.length > 0) {
        for (let x = 0; x < allAccount.length; x++) {
            const tmpLine: string = allAccount[x][1];
            const tmpSplitedLine: string[] = tmpLine.split(";");
            allFinalAccount.push({
                id: tmpSplitedLine[0],
                title: tmpSplitedLine[1],
                typeAccount: tmpSplitedLine[2] as ENU_ACCOUNT_TYPE,
                amount: parseFloat(tmpSplitedLine[3])
            })
        }
    }


    return {
        year: year,
        month: month,
        lines: allFinalLine,
        accounts: allFinalAccount
    } as I_COMPTA_FORM_NOTE

}

export const GenerateComptaBodyFromData = (month: number, year: number, accounts: I_COMPTA_ACCOUNT_ITEM[], comptaLines: I_COMPTA_FORM_ITEM[]) => {
    let finalComptaNoteBody: string = "&amp;&amp;WASP-CMTA-FORM&amp;&amp;"; //Indicateur de note de type compta

    finalComptaNoteBody += `&amp;&amp;MONTH:` + month.toString() + `&amp;&amp;&amp;&amp;YEAR:` + year.toString() + `&amp;&amp;`; //Année et mois comptable

    //Extraction des comptes
    //----------------------
    (accounts).forEach(a => {
        finalComptaNoteBody += "&amp;&amp;ACCOUNT:" + a.id + ";" + a.title + ";" + a.typeAccount.toString() + ";" + a.amount + "&amp;&amp;";
    });

    //Extraction des lignes
    //---------------------
    (comptaLines).forEach(l => {
        finalComptaNoteBody += "&amp;&amp;DATA:" + l.idLigne + ";" + l.lineType + ";" + l.amount + ";" + l.taxeRatio + ";" + l.description + ";" + l.source + ";" + l.destination + "&amp;&amp;";
    });

    return finalComptaNoteBody;
}

export const insertAt = (array: object[], index: number, ...elementArray: never[]) => {
    array.splice(index, 0, ...elementArray);
}


export const IsTypeNoteIsAnCupBord = (typeNoteTitle: string) => {
    if (typeNoteTitle !== null && typeNoteTitle.split(CupBoardCode).length > 1) {
        return true;
    } else {
        return false;
    }
}


export const FormatTextWithKey = (text: string, keys: string[]) => {
    let finalText: string = text.toUpperCase();
    (keys).forEach(k => {
        finalText = finalText.split(k.toUpperCase()).join("<span class='TEXT_RESULT_KEY'>" + k.toUpperCase() + "</span>");
    });


    return finalText;
}

export const ClearEmoji = (value: string) => {
    value.replace(
        /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
        ''
    )
        .replace(/\s+/g, ' ')
        .trim();

    return value;
}

export const UpdateAnchorNavigator = function (id: string) {
    if ($("#INDICATOR_" + id).length > 0 && id.length > 0) {
        if (window.location.href.indexOf("NOTE_ITEM_" + id) > -1)
            $("#INDICATOR_" + id).css("opacity", "1");
        if (window.location.href.indexOf("NOTE_ITEM_" + id) < 0)
            $("#INDICATOR_" + id).css("opacity", "0");
    }
}

export const ManageRichTXT_Scrool = function () {
    $(document).find('div[class~="e-toolbar"]').each(function () {
        var current = $(this);
        console.log("ici scroll");
        console.log(current);
    })
}

export enum ENU_Noteconvertion {
    toParentForm,
    toSimpleNote,
    toParentChild
};

export const getCurrentUserSessionID = (appUserSessionID: string) => {
    if (ORDO_PROCESS_ITEM.getInstance().USER_CONNECTION_ID !== undefined &&
        appUserSessionID.length > 0 &&
        (ORDO_PROCESS_ITEM.getInstance().USER_CONNECTION_ID as string).length > 0 &&
        appUserSessionID !== (ORDO_PROCESS_ITEM.getInstance().USER_CONNECTION_ID as string)) {
        return (ORDO_PROCESS_ITEM.getInstance().USER_CONNECTION_ID as string);
    } else {
        return appUserSessionID;
    }
}

export enum ENU_REMINDER_UNITY { days, houres, minutes, seconds }

export const ComputeTimeReminding = (currentDate: Date) => {
    var today = new Date();
    var diffMs = (today.getTime() - currentDate.getTime()); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var diffMonth = Math.floor(diffDays / 30);
    var diffYear = Math.floor(diffDays / 360);

    let finalTimeRemining: string =
        diffYear > 0 ? "<span class='DAY_LEFT_BOX_COUNT'>" + diffYear + "</span> an(s)" :
            diffMonth > 0 ? "<span class='DAY_LEFT_BOX_COUNT'>" + diffMonth + "</span> Mois" :
                diffDays > 0 ? "<span class='DAY_LEFT_BOX_COUNT'>" + diffDays + "</span> jr(s)" :
                    diffHrs > 0 ? "<span class='DAY_LEFT_BOX_COUNT'>" + diffHrs + "</span> Heure(s)" :
                        diffMins > 0 ? "<span class='DAY_LEFT_BOX_COUNT'>" + diffMins + "</span> Minute(s)" : " moins de " + "<span class='DAY_LEFT_BOX_COUNT'>1</span> Minute";

    return finalTimeRemining;

};