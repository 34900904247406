import * as $ from "jquery";
import { IConfig } from "../02_Interfaces/IAPP";
import { ENU_FILE_TYPE, ENU_ICON_TYPE, I_CONFIG_ITEM, I_DATE_OBJ, I_RESULT } from "../02_Interfaces/IGLOBAL";
import { ENU_TYPE_NOTE, I_GROUPE_NOTE_ITEM, I_NOTE_OVER_GROUP_ITEM, I_TYPENOTE } from "../02_Interfaces/INOTE";
import { typeNoteIdFromString, GetDateObjectFromString, getCurrentUserSessionID } from "./tools";
import { ColorsPicker } from "../01_APPDATA/DB_APP";
import { I_User } from "../02_Interfaces/IUser";
import { I_SHARED_ITEM } from "../02_Interfaces/ISHARER";
import { ORDO_PROCESS_ITEM } from "./WASPORDO";


// const webServiceUrl: string = "http://localhost:8085/";
const webServiceUrl: string = "https://waspnote.net/test/";
// const webServiceUrl: string = "https://waspnote.net/dsy/";
// const webServiceUrl: string = "https://waspnote.net/mine/";
// const webServiceUrl: string = "https://thedarilserver.synology.me:8085/";
// const webServiceUrl: string = "https://waspnote.hopto.org:8085/";//Connnection au NAS
// const webServiceUrl: string = "https://waspnote.net/";//Connnection au NAS 


//Ajout du groupe au favoris
//--------------------------
export var GetUserFavoris = (iduser: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-favoris-group",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId:getCurrentUserSessionID(iduser),
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible", data: err };
            _data(result);
        }
    });
}

//Ajout du groupe au favoris
//--------------------------
export var AddGroupToFavoris = (iduser: string, idGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "add-group-in-favorit",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(iduser),
            idGroup: idGroup,
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible d'ajouter le groupe aux favorits", data: err };
            _data(result);
        }
    });
}

//Suppression du groupe dans les favoris
//-------------------------------------
export var RemoveGroupToFavoris = (idPinedGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "remove-group-in-favorit",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idPinedGroup: idPinedGroup,
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de retirer le groupe des favoris", data: err };
            _data(result);
            // console.log(err);
        }
    });
}

//Recherche globale
//------------------------------------
export var SearchNoteByKeyWord = async  (iduser: string, searchKey: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-notes-by-search",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            searchKey: searchKey,
            iduser: getCurrentUserSessionID(iduser)
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible rechercher dans les notes", data: err };
            _data(result);
            // console.log(err);
        }
    });
}

//Création d'une notification
//------------------------------------
export var CreateNotif = (userId: string, groupId: number, targetUserId: number, typeId: number, noteId: number, forComent: boolean, asLock: boolean, _data: any) => {
    $.ajax({
        url: webServiceUrl + "create-new-notif",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(userId),
            groupId: groupId,
            typeId: typeId,
            noteId: noteId,
            targetUserId: targetUserId,
            forComent: forComent,
            asLock: asLock ? 1 : 0
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: data };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de créer une notification pour les utilisateurs", data: err };
            _data(result);
            // console.log(err);
        }
    });
}

//Déverrouillage de la note
//-------------------------
export var UnlockNote = (userId: string, idNote: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-note-lock",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(userId),
            idNote: idNote
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: data };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de déverouiller la note", data: err };
            _data(result);
            // console.log(err);
        }
    });
}

//Création d'une notification
//------------------------------------
export var DeleteNotif = async (idNotif: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "notif-readed",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idNotif: idNotif
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: data };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de supprimer la notification", data: err };
            _data(result);
            // console.log(err);
        }
    });
}

//Création d'une notification
//------------------------------------
export var GetNoteVideos = (groupNoteId: number, iduser: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-notes-video-of-group",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            groupNoteId: groupNoteId,
            iduser: getCurrentUserSessionID(iduser)
        },
        success: (data) => {

            const tmpData = JSON.parse(data).result;
            let tmpVideos: number[] = [];
            for (let x = 0; x < tmpData.length; x++) {
                tmpVideos.push(tmpData[x].idnote);
            }

            var result: I_RESULT = { status: true, data: tmpVideos };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de rechercher rechercher les vidéos", data: err };
            _data(result);
            // console.log(err);
        }
    });
}

//Recherche des notifications de l'utilisateurs
//------------------------------------
export var GetAllNotif = async (userId: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-notif",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(userId),
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de rechercher les notifications de lutilisateur", data: err };
            _data(result);
        }
    });
}

//Partage de l'élément avec le contact
//------------------------------------
export var ShareItemForUser = (userId: string, shareOption: I_SHARED_ITEM, _data: any) => {
    $.ajax({
        url: webServiceUrl + "add-share-for-user",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(userId),
            idContact: shareOption.idContact,
            idGroup: shareOption.idGroup,
            idType: shareOption.idType,
            idNote: shareOption.idNote,
            canEdit: shareOption.canEdit ? 1 : 0,
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: data };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de partager l'élément.", data: err };
            _data(result);
        }
    });
}

//Recherche des partages de l'utilisateur
//---------------------------------------
export var GetAllSharedItemForUser = (userId: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-shared-item-forUser-us",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(userId),
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de récupérer les partage de l'utilisateur.", data: err };
            _data(result);
        }
    });
}

//Recherche des partages de l'utilisateur à l'aide de l'ID
//--------------------------------------------------------
export var GetAllSharedItemForUserById = (userId: number, idGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-shared-item-forUser-with-id",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded"
        },
        data: {
            userId: userId,
            groupId: idGroup
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de récupérer les partage de l'utilisateur.", data: err };
            _data(result);
        }
    });
}

//Recherche des partages pour l'utilisateur
//---------------------------------------
export var GetAllSharedItemToUser = async (userId: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-shared-item-ToUser-us",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded"
        },
        data: {
            userId: userId,
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de récupérer les partage de l'utilisateur.", data: err };
            _data(result);
        }
    });
}

//Mise à jour du partage
//----------------------
export var UpdateSharedItemForUser = (idShare: number, canEdit: boolean, _data: any) => {
    $.ajax({
        url: webServiceUrl + "update-share-for-user",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded"
        },
        data: {
            idShare: idShare,
            canEdit: canEdit ? 1 : 0
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de récupérer les partage de l'utilisateur.", data: err };
            _data(result);
        }
    });
}

//Suppression du partage
//----------------------
export var DeleteSharedItemForUser = (idShare: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-shared-item",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded"
        },
        data: {
            idShare: idShare,
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de supprimer le partage.", data: err };
            _data(result);
        }
    });
}

//Création d'un nouveau profil
//-----------------------------
export var CreateNewProfil = (login: string, password: string, _data: any) => {
    // console.log(password);
    $.ajax({
        url: webServiceUrl + "create-account",
        method: "POST",
        async: false,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            login: login,
            userkey: password
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: data };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de créer l'utilisateur.", data: err };
            console.log(result);
            _data(result);
        }
    });
}

//Controle de la session de l'utilisateur
//---------------------------------------
export const DoesUserSessionIsActive =async (sessionKey: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "does-user-session-exist",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            sessionKey: sessionKey
        },
        success: (data) => {
            let results: any = JSON.parse(data).result;

            if (results === null) {
                _data({
                    status: false,
                    data: null
                } as I_RESULT);
            } else {
                _data({
                    status: true,
                    data: results
                } as I_RESULT);
            }

        },
        error: (error) => {
            _data({
                status: false,
                data: error
            } as I_RESULT)
        }
    })
}

//Invitation d'un utilisateur
//---------------------------
export var AddContactForUer = (userId: string, idContact: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "add-contact-for-user",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(userId),
            contactId: idContact
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: data };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de trouver les contacts.", data: err };
            _data(result);
        }
    });
}

//Accepter l'invitation
//---------------------------
export var AcceptTheInvitation = (idContact: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "accept-invitation",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idContact: idContact
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: data };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible d'accepter l'invitation.", data: err };
            _data(result);
        }
    });
}

//Supprimer l'invitation
//---------------------------
export var DeleteContact = (idContact: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-contact",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idContact: idContact
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: data };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de supprimer le contact.", data: err };
            _data(result);
        }
    });
}


//Recherche des contacts de l'utilisateur
//---------------------------------------
// export var GetCurrentUserInvitations = (userId: string, _data: any) => {
//     $.ajax({
//         url: webServiceUrl + "get-user-contacts",
//         method: "POST",
//         async: true,
//         contentType: 'application/json',
//         headers: {
//             "content-type": "application/x-www-form-urlencoded"
//         },
//         data: {
//             idUser: userId
//         },
//         success: (data) => {
//             var result: I_RESULT = { status: true, data: data };
//             _data(result);
//         },
//         error: (err) => {
//             var result: I_RESULT = { status: false, message: "Impossible de trouver les contacts.", data: err };
//             _data(result);
//         }
//     });
// }

//Recherche des contacts de l'utilisateur
//---------------------------------------
export var GetCurrentUserContacts = (userId: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-user-contacts",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idUser: getCurrentUserSessionID(userId)
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de trouver les contacts.", data: err };
            _data(result);
        }
    });
}

//Recherche de tous les profils annuaires(public et actif)
//-----------------------------
export var GetAllPublicUser = (idUser: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-public-user",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(idUser)
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de trouver les profils.", data: err };
            _data(result);
        }
    });
}

//Recherche de tous les contacts de l'utilisateur
//-----------------------------
export var GetAllUserContacts = (idUser: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-user-contacts",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(idUser)
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de trouver les profils.", data: err };
            _data(result);
        }
    });
}

//Recherche de tous les contacts de l'utilisateur
//-----------------------------
export var GetAllAuthorContacts = (idUser: number, idGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-author-contact-for-group",
        method: "POST",
        async: true,
        contentType: 'application/json',
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            author: idUser,
            idGroup: idGroup
        },
        success: (data) => {
            var result: I_RESULT = { status: true, data: JSON.parse(data).result };
            _data(result);
        },
        error: (err) => {
            var result: I_RESULT = { status: false, message: "Impossible de trouver les profils.", data: err };
            _data(result);
        }
    });
}

//Vérifier si l'utilisateur exist
//-------------------------------
export const DoesUserExist = async  (login: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "does-account-exist",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            login: login
        },
        success: (data) => {
            //Controle de la valeur
            let results: any = JSON.parse(data).result;
            
            if ( parseInt(results[0].count) > 0) {
                const result: I_RESULT = {
                    status: true
                };
                _data(result);
            } else {
                const result: I_RESULT = {
                    status: false
                };
                _data(result);
            }
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible d'exécuter la requête",
                data: err
            };
            _data(result);
        }
    })
}

//Connexion de l'utilisateur
//--------------------------
export var ConnectUser = async (login: string, password: string, _data: any) => {
    // console.log(password);
    $.ajax({
        url: webServiceUrl + "control-user-account",
        method: "POST",
        async: false,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            login: login, password: password
        },
        success: (data) => {
            let results: any = JSON.parse(data).result;

            let user: I_User = {
                login: results.login,
                id: results.userId as string
            }

            // console.log(results);

            if (!(results.status as boolean)) {
                _data({
                    status: false,
                    message: "Login ou mot de passe incorrect..."
                });
            } else {
                _data({
                    status: true,
                    data: user
                });
            }


        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                data: err,
                message: "Une erreur est survenue lors de la connexion"
            }
            _data(result);
        }
    })
}

//Recherche des groupes de notes
//------------------------------
export var GetAllGroupsNotes = async  (userId: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-groupsnotes",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(userId)
        },
        success: (data) => {
            let result = JSON.parse(data).result;
            let tmpGroups: I_GROUPE_NOTE_ITEM[] = result as I_GROUPE_NOTE_ITEM[];

            // console.log(result);

            _data({
                status: true,
                data: tmpGroups
            } as I_RESULT);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de trouver les groupes de notes",
                data: err
            }
            _data(result);
        }
    })
}

//Création d'un groupe de notes
//------------------------------
export var CreateGroupNotes = (userId: string, title: string, description: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "create-new-groupnotes",
        method: "POST",
        async: false,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(userId),
            title: title,
            description: description,
            memory: "[]"
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                data: err,
                message: "Impossible de créer le compte."
            }
            _data(result)
        }
    })
}

//Mise à jour du groupe de notes
//------------------------------
export var UpdateGroupNotes = (groupId: number, title: string, description: string, memory: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "update-one-groupnotes",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            groupId: groupId,
            title: title,
            description: description,
            memory: memory
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }



            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                data: err,
                message: "Impossible de mettre à jour le groupe de notes"
            }
            _data(result);
        }
    })
}

//Suppression d'un groupe de note
//-------------------------------
export var DeleteGroupNote = (groupId: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-one-groupnotes",
        method: "POST",
        async: false,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            groupId: groupId
        },
        success: (data) => {
            // console.log(data);

            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                data: err,
                message: "Impossible de supprimer le type de note à cause d'une erreur innatendues."
            }
            _data(result);
        }
    })
}

//Recherche des types de note du groupe de note
//---------------------------------------------
export var GetAllGroupTypes =  async  (groupId: number, userId: string, _data: any, async?: boolean) => {
    $.ajax({
        url: webServiceUrl + "get-all-typenote",
        method: "POST",
        async: (async !== undefined) ? async as boolean : true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            groupId: groupId,
            userId: getCurrentUserSessionID(userId)
        },
        success: (data) => {
            //Préparation des données
            let tmpTypeNotes: I_TYPENOTE[] = (JSON.parse(data)).result;
            // console.log(tmpTypeNotes);
            (tmpTypeNotes).forEach(tt => {
                if (ColorsPicker.filter(c => c.color === tt.colorString as string).length > 0)
                    tt.color = ColorsPicker.filter(c => c.color === tt.colorString as string)[0].id;
                else
                    tt.colorString = "ffffff";
                tt.typeNote = typeNoteIdFromString(tt.typeNoteString as string);
            });
            //-----------------------
            const result: I_RESULT = {
                status: true,
                data: tmpTypeNotes
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                data: err,
                message: "Impossible de rechercher les types de notes"
            }
            _data(result);
        }
    })
}

//Recherche du nombre de taches non terminées et datées
//------------------------------------------------------
export var GetCountTaskUncheckedWithDate = (groupId: number, userId: string, _data: any, async?: boolean) => {
    $.ajax({
        url: webServiceUrl + "get-count-task-uncheked-with-date",
        method: "POST",
        async: (async !== undefined) ? async as boolean : true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idGroup: groupId,
            userId: getCurrentUserSessionID(userId)
        },
        success: (data) => {
            //Préparation des données
            let tmpResult: any = (JSON.parse(data)).result[0].allTaskWithDate;

            // console.log(tmpResult);

            const result: I_RESULT = {
                status: true,
                data: tmpResult
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                data: err,
                message: "Impossible trouver la valeur"
            }
            _data(result);
        }
    })
}

//Recherche du nombre de notes
//------------------------------------------------------
export var GetCountNotesOfGroup = async (groupId: number, userId: string, _data: any, async?: boolean) => {
    $.ajax({
        url: webServiceUrl + "get-count-note",
        method: "POST",
        async: (async !== undefined) ? async as boolean : true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idGroup: groupId,
            userId: getCurrentUserSessionID(userId)
        },
        success: (data) => {
            //Préparation des données
            let tmpResult: any = (JSON.parse(data)).result[0].AllNote;

            // console.log(tmpResult);

            const result: I_RESULT = {
                status: true,
                data: tmpResult
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                data: err,
                message: "Impossible trouver la valeur"
            }
            _data(result);
        }
    })
}

//Recherche du nombre de taches non terminées et non datées
//------------------------------------------------------
export var GetCountTaskUncheckedWithOutDate = (groupId: number, iduser: string, _data: any, async?: boolean) => {
    $.ajax({
        url: webServiceUrl + "get-count-task-uncheked-without-date",
        method: "POST",
        async: (async !== undefined) ? async as boolean : true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded"
        },
        data: {
            idGroup: groupId,
            userId: getCurrentUserSessionID(iduser)
        },
        success: (data) => {
            //Préparation des données
            let tmpResult: any = (JSON.parse(data)).result[0].allTaskWithOutDate;

            // console.log(tmpResult);

            const result: I_RESULT = {
                status: true,
                data: tmpResult
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                data: err,
                message: "Impossible trouver la valeur"
            }
            _data(result);
        }
    })
}

//Creation d'un type de note
//--------------------------
export var CreateTypeNote = (groupId: number, userId: string, title: string, color: string, typeNote: ENU_TYPE_NOTE, _data: any) => {
    $.ajax({
        url: webServiceUrl + "create-typenote",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            groupId: groupId,
            userId: getCurrentUserSessionID(userId),
            title: title,
            color: color,
            typeNote: ENU_TYPE_NOTE[typeNote],
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result.insertId
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de créer un type de notes",
                data: err
            }
            _data(result);
        }
    })
}

//Edition d'un type de note
//--------------------------
export var UpdateTypeNote = (typeId: number, title: string, typeNote: ENU_ICON_TYPE, color: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "update-typenote",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            typeId: typeId,
            title: title,
            typeNote: ENU_TYPE_NOTE[typeNote],
            color: color
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour le type de note",
                data: err
            }
            _data(result);
        }
    })
}

//Suppression d'un type de note
//-----------------------------
export var DeleteTypeNote = (typeId: number, idGroup: number, userId: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-one-typenote",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            typeNoteId: typeId,
            userId: getCurrentUserSessionID(userId),
            groupId: idGroup
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de supprimer le type de note",
                data: err
            }
            _data(result);
        }
    })
}

//Création d'un note
//------------------
export var CreateNote = async (created: string, userId: string, groupId: number, title: string, note: string, noteRaw: string, displayUnity: number, typeNote: number, tags: string[], _data: any) => {
    //prépration des tags
    let tmptags: string = "";
    (tags).forEach(t => {
        tmptags += t + ";";
    });
    //------------------

    $.ajax({
        url: webServiceUrl + "create-new-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            created: created,
            userId: getCurrentUserSessionID(userId),
            groupId: groupId,
            title: title,
            note: note,
            typeNote: typeNote,
            tags: tmptags,
            noteRaw: noteRaw,
            displayUnity: displayUnity
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result.insertId
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de créer la note",
                data: err
            }
            _data(result);
        }
    })
}

//Mise à jour de la note
//----------------------
export var UpdateNote = async  (
    idNote: number,
    title: string,
    note: string,
    noteRaw: string,
    displayUnity: number,
    typeNote: number,
    tags: string[],
    modified: string,
    created: string,
    editor: string, _data: any) => {
    //prépration des tags
    let tmptags: string = "";
    (tags).forEach(t => {
        tmptags += t + ";";
    });

    // console.log(note);
    //------------------

    $.ajax({
        url: webServiceUrl + "update-the-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idnote: idNote,
            title: title,
            note: note,
            noteRaw: noteRaw,
            typeNote: typeNote,
            tags: tmptags,
            modified: modified,
            displayUnity: displayUnity,
            editorId: editor,
            created: created
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result.insertId
            }
            _data(result);
        },
        error: (err) => {
            // console.log(err);

            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour la note",
                data: err
            }
            _data(result);
        }
    })
}

//Mise à jour des associations de la note
//---------------------------------------
export var UpdateNoteAssociation = (idNote: number, associations: number[], _data: any) => {
    //prépration des tags
    let tmpAssociation: string = "";
    (associations).forEach(a => {
        tmpAssociation += a + ";";
    });
    //------------------

    $.ajax({
        url: webServiceUrl + "update-note-associations",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            associatedNotes: tmpAssociation,
            idnote: idNote
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result.insertId
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour la note",
                data: err
            }
            _data(result);
        }
    })
}

//épinglage ou désépinglage de la note
//------------------------------------
export var UpdatePinedNoteStatus = (idNote: number, pined: boolean, _data: any) => {
    $.ajax({
        url: webServiceUrl + "pine-or-unpine-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idnote: idNote,
            pined: pined
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result.insertId
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour la note",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche de toute les notes du groupe
//--------------------------------------
export var GetAllNotes =  async   (idgroup: number, iduser: string, startId: number, _data: any, sync?: boolean) => {
    $.ajax({
        url: webServiceUrl + "get-all-notes",
        method: "POST",
        async: sync !== undefined && sync ? true : false,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idgroup: idgroup,
            iduser: getCurrentUserSessionID(iduser), //iduser,
            startId: startId
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }

            // console.log(result);
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de trouver notes",
                data: err
            }
            _data(result);
        }
    })
}

//Mise à jour du mot de passe
//===========================
export var UpdateUserPW = (idUser: string, pw: string, _data: any, sync?: boolean) => {
    $.ajax({
        url: webServiceUrl + "update-pass",
        method: "POST",
        async: sync !== undefined ? sync as boolean : true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            userId: getCurrentUserSessionID(idUser),
            password: pw
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour le profil",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche de la note du groupe
export var GetNote =  async  (idgroup: number, iduser: string, idnote: number, _data: any, sync?: boolean) => {
    try {
        $.ajax({
            url: webServiceUrl + "get-one-note",
            method: "POST",
            async: sync !== undefined ? sync as boolean : true,
            contentType: "application/javascript",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                // "Access-Control-Allow-Methods": "POST",
                // "Access-Control-Allow-Headers": "Content-Type, Authorization",
                "Access-Control-Allow-Origin": "*",
            },
            data: {
                idgroup: idgroup,
                iduser: getCurrentUserSessionID(iduser),
                idnote: idnote
            },
            success: (data) => {
                // console.log((JSON.parse(data)).result)
                const result: I_RESULT = {
                    status: true,
                    data: (JSON.parse(data)).result[0]
                }

                _data(result);
            },
            error: (err) => {
                const result: I_RESULT = {
                    status: false,
                    message: "Impossible de trouver notes",
                    data: undefined
                }
                _data(result);
            }
        })
    } catch (err) {
        const result: I_RESULT = {
            status: false,
            message: "Impossible de trouver notes",
            data: err
        }
        _data(result);
    }
}

//Suppression de la note
//----------------------
export var DeleteNote = (idnote: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-the-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idnote: idnote
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result.insertId
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de créer la note",
                data: err
            }
            _data(result);
        }
    })
}

//Ajout d'un rappel pour la note
//------------------------------
export var AddReminderOnNote = (idNote: number, reminderdate: string, description: string, idGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "insert-reminder-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idnote: idNote,
            reminderdate: reminderdate,
            description: description,
            idgroup: idGroup
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible d'ajouter un rappel à la note",
                data: err
            }
            _data(result);
        }
    })
}

//Mise à jour du rappel de la note
//--------------------------------
export var UpdateReminderOnNote = (reminderdate: string, description: string, idNote: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "update-reminder-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            reminderdate: reminderdate,
            description: description,
            idnote: idNote
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour le rappel de la note",
                data: err
            }
            _data(result);
        }
    })
}

//Changement du statut du rappel
//-------------------------------
export var ChangeReminderStatus = (noteId: number, checkStatus: boolean, _data: any) => {
    $.ajax({
        url: webServiceUrl + "Check-reminder-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            checkValue: checkStatus,
            idnote: noteId
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour le rappel de la note",
                data: err
            }
            _data(result);
        }
    })
}

//Ajout d'une tache à la note
//---------------------------
export var AddTaskToNote = (idNote: number, title: string, taskDate: string, finished: boolean, idgroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "insert-task-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idnote: idNote,
            title: title,
            taskdate: taskDate,
            finished: finished,
            idgroup: idgroup
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible d'ajouter une tache à la note",
                data: err
            }
            _data(result);
        }
    })
};

//Mise à jour de la tache 
//-----------------------
export var UpdateTaskToNote = (idTask: number, title: string, taskdate: string, finished: boolean, _data: any) => {
    $.ajax({
        url: webServiceUrl + "update-task-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idtask: idTask,
            title: title,
            taskdate: taskdate,
            finished: finished
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour la tache de la note",
                data: err
            }
            _data(result);
        }
    })
}

//Changement du statut de la tache
//--------------------------------
export var ChangeTaskStatus = (idTask: number, finished: boolean, _data: any) => {
    $.ajax({
        url: webServiceUrl + "check-or-uncheck-task-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idtask: idTask,
            finished: finished
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour la tache de la note",
                data: err
            }
            _data(result);
        }
    })
}

//Suppression de la tache de la note
//----------------------------------
export var DeleteTaskToNote = (idTask: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-task-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idtask: idTask
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de supprimer la tache de la note",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche de toutes les taches du groupe
//----------------------------------------
export var GetAllTask = async   (idGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-task",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idgroup: idGroup
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de récupérer les taches du groupe",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche de toutes les taches du groupe
//----------------------------------------
export var GetAllReminder = (idGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-reminder",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idgroup: idGroup
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de récupérer les rappels",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche toutes les pièces jointes des notes
//---------------------------------------------
export var GetAllAttachment =async  (idGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-group-note-pj",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            groupid: idGroup
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de récupérer les pièces jointes du groupe",
                data: err
            }
            _data(result);
        }
    })
}

//Ajouter une pièce jointe à la note
//-----------------------------------
export var AddAttachmentTonote = (idGroup: number, fileName: string, fileContent: string, idNote: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "add-file-to-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            // "content-type": "application/json",
            // "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idgroup: idGroup,
            filename: fileName,
            fileContent: fileContent,
            idnote: idNote
        },
        success: (data) => {
            // console.log("ok");
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            // console.log(result);
            _data(result);
        },
        error: (err) => {
            // console.log("KO");
            const result: I_RESULT = {
                status: false,
                message: "Impossible d'ajouter la pièce jointe",
                data: err
            }
            _data(result);
        }
    })
}

//Suppression d'une pièce jointe de la note
//-----------------------------------------
export var DeleteAttachmentOfNote = (idFile: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-file-of-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            fileId: idFile
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de supprimer la pièce jointe",
                data: err
            }
            _data(result);
        }
    })
}

//REcherche du contenu de la note
//------------------------------
export var GetAttachmentFileContent = (idFile: number, _data: any, async?: boolean) => {
    $.ajax({
        url: webServiceUrl + "get-file-data",
        method: "POST",
        async: async !== undefined ? async : true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            fileId: idFile
        },
        success: (data) => {
            // console.log( (JSON.parse(data)).result);
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }

            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de récupérer le contenu de la pièce jointe.",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche du profil de l'utilisateur
//------------------------------------
export var GetUserProfil = (idUser: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-user-profil-information",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            iduser: getCurrentUserSessionID(idUser)
        },
        success: (data) => {
            // console.log(data);
            const result: I_RESULT = {
                status: (JSON.parse(data)).result.length === 0 ? false : true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de récupérer le profil de l'utilisateur.",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche du profil de l'utilisateur
//------------------------------------
export var GetUserLogin = (userId: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-user-login",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            key: getCurrentUserSessionID(userId)
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible d'effectuer cette action.",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche du profil de l'utilisateur
//------------------------------------
export var CreateUserProfil = (idUser: string, email: string, firstName: string, lastName: string, visbility: boolean, _data: any) => {
    $.ajax({
        url: webServiceUrl + "add-user-profil-information",
        method: "POST",
        async: false,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            iduser: getCurrentUserSessionID(idUser),
            email: email,
            firstname: firstName,
            lastname: lastName,
            visibility: visbility ? 1 : 0
        },
        success: (data) => {
            // console.log(data);
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de créer le profil de l'utilisateur.",
                data: err
            }
            _data(result);
        }
    })
}

//Mise à jour du profil de l'utilisateur
//------------------------------------
export var UpdateUserProfil = (idUser: string, email: string, firstName: string, lastName: string, visibility: boolean, _data: any) => {
    $.ajax({
        url: webServiceUrl + "update-user-profil",
        method: "POST",
        async: false,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            iduser: getCurrentUserSessionID(idUser),
            email: email,
            firstname: firstName,
            lastname: lastName,
            visibility: visibility
        },
        success: (data) => {
            // console.log(data);
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible de mettre à jour le profil de l'utilisateur.",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche de la configuration de l'utilisateur
//----------------------------------------------
export const GetAppConfiguration = ((user: I_User, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-user-config",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            iduser: user.id
        },
        success: (data) => {

            // console.log((JSON.parse(data)).result as IConfig[]);

            _data({
                data: (JSON.parse(data)).result as IConfig[],
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de récupérer les paramètres de l'application",
                status: false
            } as I_RESULT)
        }
    })
})

//Ajout d'un commentaire pour une note
//------------------------------------
export const AddComentToNote = ((idNote: number, idUP: number, coment: string, author: string, created: string, _data: any) => {

    //    console.log(idNote);
    //    console.log(coment);
    //    console.log(idUP);


    $.ajax({
        url: webServiceUrl + "add-coment-to-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idNote: idNote,
            idUP: idUP,
            author: author,
            coment: coment,
            created: created,
        },
        success: (data) => {

            // console.log((JSON.parse(data)).result as IConfig[]);
            _data({
                data: (JSON.parse(data)).result as IConfig[],
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de créer un commentaire pour la note",
                status: false
            } as I_RESULT)
        }
    })
})

//Extraire les commentaires de la note
//------------------------------------
export const GetComentsOfNote =  ( async(idNote: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-coments-of-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idNote: idNote,
        },
        success: (data) => {

            // console.log((JSON.parse(data)).result as IConfig[]);
            _data({
                data: (JSON.parse(data)).result as IConfig[],
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de récupérer les commentaires de la note",
                status: false
            } as I_RESULT)
        }
    })
})

//Extraire le commentaire de la note
//------------------------------------
export const GetComentById = ((idComent: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-coment-of-the-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idComent: idComent,
        },
        success: (data) => {

            // console.log((JSON.parse(data)).result as IConfig[]);
            _data({
                data: (JSON.parse(data)).result as IConfig[],
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de récupérer le commentaire",
                status: false
            } as I_RESULT)
        }
    })
})

//Extraire les commentaires de la note
//------------------------------------
export const DeleteComentsOfNote = ((idComent: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-coment-of-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idComent: idComent,
        },
        success: (data) => {

            // console.log((JSON.parse(data)).result as IConfig[]);
            _data({
                data: (JSON.parse(data)).result as IConfig[],
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible supprimer le commentaire",
                status: false
            } as I_RESULT)
        }
    })
})

//Suppression du commentaire de la note
//-------------------------------------
export const DeteleComentOfNote = ((idNote: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-coments-of-note",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idNote: idNote,
        },
        success: (data) => {

            // console.log((JSON.parse(data)).result as IConfig[]);
            _data({
                data: (JSON.parse(data)).result as IConfig[],
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de supprimer le commentaire",
                status: false
            } as I_RESULT)
        }
    })
})

//Création de la configuration de l'application
//---------------------------------------------
export const CreateAppConfiguration = ((idUser: number,
    showNotification: boolean,
    showOnlyGroupNotifications: number,
    selectPinnedNotesByDefault: boolean,
    selectTaskNotesBydefault: boolean,
    selectReminderNotesByDefault: boolean,
    activeMemoryByDefault: boolean,
    _data: any) => {
    $.ajax({
        url: webServiceUrl + "create-user-config",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            iduser: idUser,
            showNotification: showNotification,
            showOnlyGroupNotifications: showOnlyGroupNotifications,
            selectPinnedNotesByDefault: selectPinnedNotesByDefault,
            selectTaskNotesBydefault: selectTaskNotesBydefault,
            selectReminderNotesByDefault: selectReminderNotesByDefault,
            activeMemoryByDefault: activeMemoryByDefault
        },
        success: (data) => {
            _data({
                status: true,
                data: data.result
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible créer la configuration de l'application",
                status: false
            } as I_RESULT);
        }
    })
})

//Création d'une nouvelle entrée dans le dépôt de note liée
//Ajout d'un rappel pour la note
//------------------------------
export var addNoteOfOtherGroup = (idNote: number, idGroup: number, idUser: string, idOtherNote: number, idOtherGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "add-note-to-note-from_groups",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idNote: idNote,
            idGroup: idGroup,
            iduser: getCurrentUserSessionID(idUser),
            idOtherNote: idOtherNote,
            idOtherGroup: idOtherGroup
        },
        success: (data) => {
            const result: I_RESULT = {
                status: true,
                data: (JSON.parse(data)).result
            }
            _data(result);
        },
        error: (err) => {
            const result: I_RESULT = {
                status: false,
                message: "Impossible d'ajouter la note à la note",
                data: err
            }
            _data(result);
        }
    })
}

//Recherche de toutes les associations inter groupe
export const GetNotesOverGroups = ((idGroup: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-notes-inter-groups",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idGroup: idGroup,
        },
        success: (data) => {

            // console.log((JSON.parse(data)).result as I_NOTE_OVER_GROUP_ITEM[]);
            _data({
                data: (JSON.parse(data)).result as I_NOTE_OVER_GROUP_ITEM[],
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de récupérer notes inter groupes",
                status: false
            } as I_RESULT)
        }
    })
})

//Suppression de la liaison inter group
export const DeteleteNoteOverGroups = ((idLinkedItem: number, _data: any) => {
    $.ajax({
        url: webServiceUrl + "delete-note-inter-groups",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            idLinkedItem: idLinkedItem,
        },
        success: (data) => {
            _data({
                data: (JSON.parse(data)).result,
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de supprimé l'élément",
                status: false
            } as I_RESULT)
        }
    })
})


export const LoadFile = (file: File, data: any) => {
    let reader: FileReader = new FileReader();
    reader.onload = function (event) {
        data(event.target?.result as string);
    }
    reader.readAsDataURL(file);
}

export const getFileType = (fileName: string) => {
    const fileExtension: string = fileName.split(".")[fileName.split(".").length - 1].toUpperCase();
    if (fileExtension.indexOf("DOC") > -1) {
        return ENU_FILE_TYPE.doc;
    } else if (fileExtension.indexOf("XLS") > -1) {
        return ENU_FILE_TYPE.excel;
    } else if (fileExtension.indexOf("PPT") > -1) {
        return ENU_FILE_TYPE.presentation;
    } else if (fileExtension.indexOf("PNG") > -1 ||
        fileExtension.indexOf("JPG") > -1 ||
        fileExtension.indexOf("GIF") > -1) {
        return ENU_FILE_TYPE.picture;
    } else if (fileExtension.indexOf("PDF") > -1) {
        return ENU_FILE_TYPE.pdf;
    } else if (fileExtension.indexOf("TXT") > -1) {
        return ENU_FILE_TYPE.text;
    } else if (fileExtension.indexOf("MSG") > -1) {
        return ENU_FILE_TYPE.email;
    } else {
        return ENU_FILE_TYPE.other;
    }
}


export const dateIsPast = (dateString: string) => {
    let tmpDate: I_DATE_OBJ = GetDateObjectFromString(dateString);
    if (tmpDate.dateObj < (new Date)) {
        return true;
    } else {
        return false;
    }
}

export const dateIsNow = (dateString: string) => {
    let tmpDate: I_DATE_OBJ = GetDateObjectFromString(dateString);
    const currentDate: Date = new Date();
    if (tmpDate.dateObj.getDate() === currentDate.getDate() &&
        tmpDate.dateObj.getMonth() === currentDate.getMonth() &&
        tmpDate.dateObj.getFullYear() === currentDate.getFullYear() &&
        tmpDate.dateObj.getHours() === currentDate.getHours()) {
        return true;
    } else {
        return false;
    }
}

export const dateIsFutur = (dateString: string) => {
    let tmpDate: I_DATE_OBJ = GetDateObjectFromString(dateString);
    if (tmpDate.dateObj > (new Date)) {
        return true;
    } else {
        return false;
    }
}

export const GetAllUserConfig = ((idUser: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "get-all-user-config",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            iduser: getCurrentUserSessionID(idUser),
        },
        success: (data) => {
            _data({
                data: (JSON.parse(data)).result as I_CONFIG_ITEM[],
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de récupérer la configuration de l'utilisateur",
                status: false
            } as I_RESULT)
        }
    })
})

export const UpdateUserConfig = ((idUser: string, configValue: string, configName: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "update-config-for-user",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            iduser: getCurrentUserSessionID(idUser),
            configName: configName,
            configValue: configValue
        },
        success: (data) => {
            _data({
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de mettre à jour la configuration",
                status: false
            } as I_RESULT)
        }
    })
})

export const CreateUserConfig = ((idUser: string, configValue: string, configName: string, _data: any) => {
    $.ajax({
        url: webServiceUrl + "add-config-for-user",
        method: "POST",
        async: true,
        contentType: "application/javascript",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
        },
        data: {
            iduser: getCurrentUserSessionID(idUser),
            configName: configName,
            configValue: configValue
        },
        success: (data) => {
            _data({
                status: true
            } as I_RESULT);
        },
        error: (error) => {
            _data({
                data: error,
                message: "Impossible de créer la configuration",
                status: false
            } as I_RESULT)
        }
    })
})

