import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import * as React from "react";
import {
  ENU_ICON_TYPE,
  ENU_SHARE_TYPE,
  I_KEY_VALUE_DATA,
  I_RESULT,
} from "../../02_Interfaces/IGLOBAL";
import {
  I_ATTACHMENT_LINK_ITEM,
  I_COMMENT_NOTE_ITEM,
  I_GROUPE_NOTE_ITEM,
  I_NOTE_ITEM,
  I_NOTE_ITEM_PROPS,
  I_NOTE_ITEM_PROPS_STATE,
  I_TASK_ITEM,
  I_TYPENOTE,
} from "../../02_Interfaces/INOTE";
import {
  ComputeTimeReminding,
  ConvertVideoURL,
  CupBoardCode,
  decodeUtf8,
  ENU_Noteconvertion,
  GetDateStringFromObject,
  GetDistinctListOfString,
  GetFormatedDate,
  GetIconFromType,
  GetNoteComptaData,
  GetNoteCustFormData,
  GetNoteCustParentIdFromTitle,
  GoToElement,
  IsComptaForm,
  IsCustForm,
  IsTypeNoteIsAnCupBord,
} from "../../04_TOOLS/tools";
// import "../../99_Styles/noteItems_V3.css";
import { CONTEXTUEAL_MENU_ITEM } from "./CONTEXTUAL_MENU";
import { GetDateObjectFromString } from "../../04_TOOLS/tools";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { I_CONTEXTUAL_MENU_ITEM } from "../../02_Interfaces/ICONTEXTUAL_MENU";
import { DeleteTaskToNote, GetComentsOfNote } from "../../04_TOOLS/DB_WS";
import { I_MSG_OPTION } from "../../02_Interfaces/IMSGBOX";
import { FIELD_COMENT_BOX } from "../Fields/FIELD_COMENT_BOX";
import { I_USER_PROFIL_ITEM } from "../../02_Interfaces/IUSERPROFIL";
import { COMPTA_FORM } from "./COMPTA_FORM";
import { EYE_DYN } from "./EYE_DYN";
import { ENU_EYE_STATUS } from "../../02_Interfaces/I_EYE";
import { LAYOUT_LOADER } from "./LAYOUTLOADER";
import { CUPBOARD } from "./CUPBOARD_ITEM";
import { LOADER_BASIC } from "./LOADER_BASIC";
import { CUST_FORM_BOX } from "./CUST_FORM";
import { NOTE_CUST_FORM_EMPTY_PARENT, NOTE_CUST_FORM_EMPTY_TITLE, NOTE_CUST_FORM_PARENT_TITLE } from "../../01_APPDATA/DB_APP";
import { SEARCHBOX_ZONE } from "./SEARCHBOX_ZONE";
import { SEARCHBOX } from "./SEARCHBOX";
import { log } from "console";

export class NOTE_ITEM extends React.Component<
  I_NOTE_ITEM_PROPS,
  I_NOTE_ITEM_PROPS_STATE
> {
  constructor(props: I_NOTE_ITEM_PROPS) {
    super(props);

    this.state = {
      open: this.props.APP_GLOBAL_DATA.openedNote.filter(
        (on) => on === this.props.note.idnote
      ).length > 0 ? true : false,
      editMode: false,
      onLoad: false,
      titleValue: this.props.note.title,
      cash_Tasks: this.props.tasks as I_TASK_ITEM[],
      tagTmpValue: "",
      selectedTagIndex: -1,
      currentEditor: this.props.note.editor,
      comentMode: false,
      coments: [],
      canBeShown: false,
      showRelatedNote: false,
      currentSugestion: [],
      sugestionMaxValue: 30
    };

    this.onOpenNote = this.onOpenNote.bind(this);
    this.onCheckTask = this.onCheckTask.bind(this);
    this.onPinningNote = this.onPinningNote.bind(this);
    this.onEditMode = this.onEditMode.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeTaskTitle = this.onChangeTaskTitle.bind(this);
    this.onChangeTagTmpValue = this.onChangeTagTmpValue.bind(this);
    this.onNavigateOnExistedTags = this.onNavigateOnExistedTags.bind(this);
    this.onDeleteTag = this.onDeleteTag.bind(this);
    this.onGoBack = this.onGoBack.bind(this);
    this.onQuickSaveNote = this.onQuickSaveNote.bind(this);
    this.onOpenComentZone = this.onOpenComentZone.bind(this);
    this.getAllNoteComents = this.getAllNoteComents.bind(this);
    this.onShowIt = this.onShowIt.bind(this);
    this.onShowRelatedNotes = this.onShowRelatedNotes.bind(this);
    this.onComputeSugestion = this.onComputeSugestion.bind(this);
    this.onChangeMaxSugestionValue = this.onChangeMaxSugestionValue.bind(this);
    this.onShowSugestion = this.onShowSugestion.bind(this);


  }

  private ref_rte = React.createRef<RichTextEditorComponent>();
  private ref_fieldTag = React.createRef<HTMLInputElement>();
  private ref_title = React.createRef<HTMLInputElement>();
  private ref_comptaForm = React.createRef<COMPTA_FORM>();
  private ref_custFom = React.createRef<CUST_FORM_BOX>();
  private ref_sugestion = React.createRef<SEARCHBOX>();


  //Methodes et fonctions utiles
  //============================

  onChangeMaxSugestionValue(up: boolean) {
    if (up) {
      this.setState({ sugestionMaxValue: this.state.sugestionMaxValue + 30 }, () => {
        this.onComputeSugestion();
      })
    } else {
      this.setState({ sugestionMaxValue: this.state.sugestionMaxValue - 30 < 0 ? 0 : this.state.sugestionMaxValue - 30 }, () => {
        this.onComputeSugestion();
      })
    }


  }

  onComputeSugestion = () => {

    //découpage des mots saisis dans la zone de saisie
    if (this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
      (
        this.ref_rte.current !== null ||
        this.ref_custFom !== null
      )
    ) {
      let splitedWORDS: string[] = [];
      if (this.ref_rte.current !== null) {
        splitedWORDS = (this.ref_rte.current.getText().split('.').concat(this.ref_rte.current.getText().split(' ')))
          .filter(w =>
            w.toUpperCase() !== "DE" &&
            w.toUpperCase() !== "LA" &&
            w.toUpperCase() !== "UN" &&
            w.toUpperCase() !== "UNE" &&
            w.toUpperCase() !== "DES" &&
            w.toUpperCase() !== "LES" &&
            w.toUpperCase() !== "DANS" &&
            w.toUpperCase() !== "POUR" &&
            w.toUpperCase() !== "MAIS" &&
            w.toUpperCase() !== "AVEC"
          );
      } else if (this.ref_custFom.current !== null) {
        splitedWORDS = (decodeURIComponent(this.ref_custFom.current.state.tmpBodyContener).split('.').concat(decodeURIComponent(this.ref_custFom.current.state.tmpBodyContener).split(' ')))
          .filter(w =>
            w.toUpperCase() !== "DE" &&
            w.toUpperCase() !== "LA" &&
            w.toUpperCase() !== "UN" &&
            w.toUpperCase() !== "UNE" &&
            w.toUpperCase() !== "DES" &&
            w.toUpperCase() !== "LES" &&
            w.toUpperCase() !== "DANS" &&
            w.toUpperCase() !== "POUR" &&
            w.toUpperCase() !== "MAIS" &&
            w.toUpperCase() !== "AVEC"
          );
      }

      splitedWORDS = GetDistinctListOfString(splitedWORDS.filter(sw => sw.length > 3));

      //Ajustement des tendances
      //------------------------
      let finalSugestion: I_NOTE_ITEM[] = this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n => splitedWORDS.filter(w => n.title.toUpperCase().indexOf(w.toUpperCase()) > -1).length > 0);


      let priorityNotes: I_KEY_VALUE_DATA[] = [];
      let tmpSugestion: string[] = GetDistinctListOfString(finalSugestion.map(s => { return s.idnote.toString() }));
      for (let x = 0; x < tmpSugestion.length; x++) {
        let s: string = tmpSugestion[x];
        if (priorityNotes.filter(pn => (pn.key as I_NOTE_ITEM).idnote === parseInt(s)).length === 0)
          priorityNotes.push({
            key: finalSugestion.filter(_s => _s.idnote === parseInt(s))[0],
            value: this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined ? (this.props.APP_GLOBAL_DATA.DB_NOTES.filter(_n => _n.associations !== null && _n.associations.split(";").filter(sa => sa.length > 0 && parseInt(s) === parseInt(sa)).length > 0).length) : 1,
            data: undefined
          })
      }

      // (GetDistinctListOfString(finalSugestion.map(s => { return s.idnote.toString() }))).forEach(s => {
      //   if (priorityNotes.filter(pn => (pn.key as I_NOTE_ITEM).idnote === parseInt(s)).length === 0)
      //     priorityNotes.push({
      //       key: finalSugestion.filter(_s => _s.idnote === parseInt(s))[0],
      //       value: this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined ? (this.props.APP_GLOBAL_DATA.DB_NOTES.filter(_n => _n.associations !== null && _n.associations.split(";").filter(sa => sa.length > 0 && parseInt(s) === parseInt(sa)).length > 0).length) : 1,
      //       data: undefined
      //     })
      // });



      finalSugestion = [];//remise à zéro...


      //Rafinane[1]
      //-------------

      for (let x = 0; x < priorityNotes.length; x++) {
        let pn: I_KEY_VALUE_DATA = priorityNotes[x];
        pn.value = parseInt(pn.value) + (splitedWORDS.filter(sw => (pn.key as I_NOTE_ITEM).title.toUpperCase().indexOf(sw.toUpperCase()) > -1).length)
      }

      // (priorityNotes).forEach(pn => {
      //   pn.value = parseInt(pn.value) + (splitedWORDS.filter(sw => (pn.key as I_NOTE_ITEM).title.toUpperCase().indexOf(sw.toUpperCase()) > -1).length)
      // });
      //-------------

      priorityNotes = priorityNotes.sort((a, b) => {
        return ((b.value as number) - (a.value as number))
      });

      (priorityNotes).forEach(pn => {
        if (pn.value as number > 0)
          finalSugestion.push(pn.key as I_NOTE_ITEM);
      });

      //------------------------


      this.props.APP_GLOBAL_DATA.DB_NOTES &&
        this.setState({
          currentSugestion: finalSugestion
            .filter(s => this.ref_sugestion.current !== null && this.ref_sugestion.current.state.searchValue.length > 0 ?
              s.title.toUpperCase().indexOf(this.ref_sugestion.current.state.searchValue.toUpperCase()) > -1 : true)
            .slice(0, this.state.sugestionMaxValue),
          sugestionMaxValue: this.state.sugestionMaxValue > finalSugestion.length ? finalSugestion.length : this.state.sugestionMaxValue
        })

    }



  }

  onShowSugestion = (sugestion: I_NOTE_ITEM) => {

    if (sugestion.description.length === 0)
      (this.props.APP_GLOBAL_FUNCTION.onLoadNoteData as Function)(sugestion.idnote);
    this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
      this.setState({}, () => {

        let noteDescriptionControl = setInterval(() => {
          if (this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined && this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === sugestion.idnote)[0].description.length > 0) {
            let tmpNote: I_NOTE_ITEM = this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === sugestion.idnote)[0];
            let tmpMSGOption: I_MSG_OPTION = {
              allMSG: [
                {
                  key: sugestion.title,
                  value:
                    IsCustForm(tmpNote.description) ?
                      tmpNote :
                      (this.props.APP_GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(n => n.idnote === sugestion.idnote)[0].description,
                  data: ENU_ICON_TYPE.note
                }
              ],
              buttons: [
                {
                  title: "Lier à la note courante",
                  F_BUTTON_ACTION: () => {
                    (this.props.APP_GLOBAL_FUNCTION.onLinkItemToNote as Function)(sugestion.idnote, this.props.note);
                    (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)()
                  }
                }
              ],
              showCloseButton: true
            };

            (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(tmpMSGOption);
            clearInterval(noteDescriptionControl)
          } else {
            //-->
          }
        }, 300);


      })


  }

  onShowRelatedNotes() {
    this.setState({ showRelatedNote: !this.state.showRelatedNote }, () => { });
  }

  onShowIt = () => {
    setTimeout(() => {
      this.setState({
        canBeShown: this.props.note.description.length > 0 ? true : false,
        open:
          (this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
            this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type).length > 0 &&
            IsTypeNoteIsAnCupBord(this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title)
          ) ?
            (this.props.note.title === "..." || (this.props.note.description.length === 0 &&
              !IsTypeNoteIsAnCupBord(this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title)
            ) ? true : false) :
            this.props.note.description.length > 0 ? true : false
      }, () => {
        if (this.props.APP_GLOBAL_DATA.filterByOne !== undefined && this.props.note.idnote === this.props.APP_GLOBAL_DATA.filterByOne) {
          (this.props.APP_GLOBAL_FUNCTION.onLoadNoteData as Function)(this.props.note.idnote, true);
        }
      });
    }, 1000); //Force la temporatisation des traitements et permet l'interception d'actions intermédiaires
  }

  getAllNoteComents = async (closeZone?: boolean, localThread?: number) => {
    try {
      GetComentsOfNote(this.props.note.idnote, async (data: I_RESULT) => {
        try {
          if (data.status) {
            this.setState({
              coments: data.data as I_COMMENT_NOTE_ITEM[],
              comentMode: closeZone !== undefined ? closeZone : false,
            }, () => {
              //Rappèle récursive
              setTimeout(() => {
                if (this.state.open! && this.state.onLoad)
                  if (localThread === undefined)
                    this.getAllNoteComents(this.state.comentMode, this.props.currentThread);
                  else if (localThread !== undefined && localThread === this.props.currentThread) {
                    this.getAllNoteComents(this.state.comentMode, localThread);
                  }
              }, 3000);
              //----------------
            });
          } else {
            //-->
          }
        } catch (err) {
          console.log(err);
        }
      });
    } catch (err) {
      console.log(err);
    }

  }

  onOpenComentZone() {
    this.setState({ comentMode: !this.state.comentMode });
  }

  onDeleteTag = (tag: string) => {
    if (this.ref_fieldTag.current !== null) {
      let tmpNote: I_NOTE_ITEM = this.props.note;
      tmpNote.tags =
        tmpNote.tags
          .split(";")
          .filter((t) => t !== "" && t !== tag)
          .join(";") + ";";

      //Mise à jour de la note
      (this.props.APP_GLOBAL_FUNCTION.updateNote as Function)(
        tmpNote,
        this.state.cash_Tasks,
        true
      );
    }
  }

  onSelectTag = async (tag: string) => {


    // this.onQuickSaveNote();

    this.setState({ selectedTagIndex: -1, tagTmpValue: "" }, () => {
      let tmpNote: I_NOTE_ITEM = this.props.note;
      tmpNote.tags += tag + ";";
      (this.props.APP_GLOBAL_FUNCTION.updateNote as Function)(
        tmpNote,
        this.state.cash_Tasks,
        true
      );

      if (this.ref_fieldTag.current !== null) {
        this.ref_fieldTag.current.value = "";
      }

    });



  }

  onNavigateOnExistedTags = (e: React.KeyboardEvent, currentValue: string) => {
    if (this.ref_fieldTag.current !== null) {
      if (e.which === 13) {
        let tmpNote: I_NOTE_ITEM = this.props.note;

        tmpNote.tags += this.ref_fieldTag.current.value + ";";

        this.setState({ selectedTagIndex: -1, tagTmpValue: "" }, () => {
          (this.props.APP_GLOBAL_FUNCTION.updateNote as Function)(
            tmpNote,
            this.state.cash_Tasks,
            true
          );
        });
      } else if (e.which === 40) {
        if (
          this.state.selectedTagIndex <
          this.props.tags
            .filter(
              (_t) =>
                _t !== "" &&
                _t.toUpperCase().indexOf(this.state.tagTmpValue.toUpperCase()) >
                -1
            )
            .sort((a, b) => {
              return a.localeCompare(b);
            })
            .slice(0, 10).length -
          1
        ) {
          this.setState(
            {
              selectedTagIndex: this.state.selectedTagIndex + 1,
            },
            () => {
              if (this.ref_fieldTag.current !== null)
                this.ref_fieldTag.current.value = this.props.tags
                  .filter(
                    (_t) =>
                      _t !== "" &&
                      _t.toUpperCase().indexOf(currentValue.toUpperCase()) > -1
                  )
                  .sort((a, b) => {
                    return a.localeCompare(b);
                  })
                  .slice(0, 10)[this.state.selectedTagIndex];
            }
          );
        }
      } else if (e.which === 38) {
        if (this.state.selectedTagIndex >= 1) {
          this.setState(
            {
              selectedTagIndex: this.state.selectedTagIndex - 1,
            },
            () => {
              if (this.ref_fieldTag.current !== null)
                this.ref_fieldTag.current.value = this.props.tags
                  .filter(
                    (_t) =>
                      _t !== "" &&
                      _t.toUpperCase().indexOf(currentValue.toUpperCase()) > -1
                  )
                  .sort((a, b) => {
                    return a.localeCompare(b);
                  })
                  .slice(0, 10)[this.state.selectedTagIndex];
            }
          );
        }
      } else if (e.which === 27) {
        if (this.ref_fieldTag.current !== null) {
          this.ref_fieldTag.current.value = "";
          this.setState({ selectedTagIndex: -1 });
        }
      }
    }
  }

  onChangeTagTmpValue = (e: React.FormEvent) => {
    this.setState({
      tagTmpValue: (e.target as HTMLInputElement).value.toUpperCase(),
      selectedTagIndex: -1,
    });
  }

  onChangeTaskTitle = (idTask: number, e: React.FormEvent) => {
    let tmpTask: I_TASK_ITEM[] = this.state.cash_Tasks
    // .filter(
    //   (ct) => ct.idtask === idTask
    // );
    tmpTask.filter((t) => t.idtask === idTask)[0].title = (
      e.target as HTMLInputElement
    ).value;
    this.setState({ cash_Tasks: tmpTask });
  }

  onChangeTitle = (e: React.FormEvent) => {
    this.setState({
      titleValue: (e.target as HTMLInputElement).value.length < 120 ?
        (e.target as HTMLInputElement).value : this.state.titleValue
    });
  }

  onOpenNote = () => {
    this.setState({ onLoad: true, open: !this.state.open }, () => {
      //Chargement du contenu de la note
      if (this.state.open) {
        // chargement des pièces jointes

        (this.props.APP_GLOBAL_FUNCTION.onLoadNoteData as Function)(
          this.props.note.idnote
        );


        (this.props.APP_GLOBAL_FUNCTION.loadAsyncAttachment as Function)(
          this.props.note.idnote,
          false
        );

        this.getAllNoteComents();


      }

      //Vidage du contenu de la note
      if (!this.state.open) {
        if (!IsComptaForm(this.props.note.description) && this.props.APP_GLOBAL_DATA.openedNote.filter(n => n === this.props.note.idnote).length < 2) {
          // (this.props.APP_GLOBAL_FUNCTION.onUnLoadNoteData as Function)(
          //   this.props.note.idnote
          // );
        }
      }

      if (this.state.open) {
        if (
          this.props.APP_GLOBAL_DATA.allNotifs.filter(
            (n) => n.noteId === (this.props.note.idnote as number)
          ).length > 0
        ) {
          const idNotif: number = this.props.APP_GLOBAL_DATA.allNotifs.filter(
            (n) => n.noteId === (this.props.note.idnote as number)
          )[0].idNotif;
          (this.props.APP_GLOBAL_FUNCTION.onReadNotifElement as Function)(
            idNotif
          );
        }
      }

      (this.props.APP_GLOBAL_FUNCTION.onOpenedNote as Function)(
        this.props.note.idnote,
        this.state.open
      );
      // (this.props.APP_GLOBAL_FUNCTION.onShowLoader as Function)(false);


    });
  }

  onGoBack() {
    //Contrôle des modification
    //-------------------------
    if (
      this.ref_rte.current?.getHtml() !== this.props.note.description ||
      this.ref_title.current?.value !== this.props.note.title
    ) {
      let msgOption: I_MSG_OPTION = {
        allMSG: [
          {
            key: "Note modifiée",
            value:
              "Des modifications sont en cours. Si vous continuer vous perdrez les modifications en cours. Voulez-vous continuer ?",
            data: ENU_ICON_TYPE.save,
          },
        ],
        buttons: [
          {
            title: "Continuer",
            F_BUTTON_ACTION: () => {
              this.setState({ editMode: false }, () => {
                (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
              });
            },
          },
        ],
        showCloseButton: true,
      };

      (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(msgOption);
    } else {
      this.setState({ editMode: false });
    }
  }

  onQuickSaveNote = (_e?: React.KeyboardEvent, convertionType?: ENU_Noteconvertion) => {


    const QUICK_SAVE = () => {
      if (
        this.ref_rte.current !== null &&
        new Blob([this.ref_rte.current.getHtml().toString()]).size / 1000000 >
        this.props.APP_GLOBAL_DATA.noteMaxSize
      ) {
        let msgOption: I_MSG_OPTION = {
          allMSG: [
            {
              key: "Taille maximale de saisie dépassée...",
              value:
                "Vous avez atteint la taille maximale de saisie dans la note, limitée à " +
                this.props.APP_GLOBAL_DATA.noteMaxSize +
                "Kb.",
              data: ENU_ICON_TYPE.note,
            },
          ],
          showCloseButton: false,
          buttons: [
            {
              title: "OK",
              F_BUTTON_ACTION: () => {
                (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
              },
            },
          ],
        };

        (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(msgOption);
      } else {
        if (this.ref_rte.current !== null && this.ref_rte.current.getHtml().length > 0) {
          let tmpNote: I_NOTE_ITEM = this.props.note;
          if (IsCustForm(this.props.note.description)) {
            tmpNote.title = NOTE_CUST_FORM_EMPTY_TITLE + NOTE_CUST_FORM_EMPTY_PARENT.replace("PARENTNOTEID", GetNoteCustFormData(this.props.note.description, this.props.note.idnote).id.toString()) + this.state.titleValue;
          } else {
            tmpNote.title = this.state.titleValue;
          }



          tmpNote.description = this.ref_rte.current.getHtml();
          tmpNote.descriptionraw = encodeURIComponent(this.ref_rte.current.getHtml());


          //Nettoyage
          if (convertionType !== undefined) {
            switch (convertionType) {
              case ENU_Noteconvertion.toSimpleNote:
                tmpNote.title = tmpNote.title.replace("WASP-CUST-FORM-PARENT-[" + this.props.note.idnote + "]-", "");
                break;
              case ENU_Noteconvertion.toParentForm:
                tmpNote.title = "WASP-CUST-FORM-PARENT-[" + this.props.note.idnote + "]-" + "Convertis en formulaire parent";
                break;
              case ENU_Noteconvertion.toParentChild:
                tmpNote.title = "WASP-CUST-FORM-PARENT-[" + this.props.note.idnote + "]-" + "Convertis en formulaire parent";
                break;
              default:
              //-->
            }

            switch (convertionType) {
              case ENU_Noteconvertion.toSimpleNote:
                tmpNote.description = "...";
                tmpNote.descriptionraw = "...";
                break;
              case ENU_Noteconvertion.toParentForm:
                tmpNote.description = "";
                tmpNote.descriptionraw = "";
                break;
              case ENU_Noteconvertion.toParentChild:
                tmpNote.description = "";
                tmpNote.descriptionraw = "";
                break;
              default:
              //-->

              //this.onQuickSaveNote();
            }
          }


          tmpNote.modified = GetFormatedDate(new Date(), true);
          //---------

          if (this.ref_rte.current !== null && this.ref_rte.current.value !== this.props.note.description) {
            (this.props.APP_GLOBAL_FUNCTION.updateNote as Function)(
              tmpNote,
              this.state.cash_Tasks,
              undefined,
              undefined,
              true,
              true
            )

            if (e !== undefined)
              e.preventDefault();
          }


        }
      }

      // if (
      //   this.ref_comptaForm.current !== null &&
      //   IsComptaForm(this.props.note.description)
      // )
      //   this.ref_comptaForm.current.onGenerateNoteBody();

    }

    let e: React.KeyboardEvent;
    if (_e !== undefined) {
      e = _e;
      if (e.ctrlKey && e.key === "s" && this.props.APP_GLOBAL_DATA.editNoteMode) {

        QUICK_SAVE();

        // e.preventDefault();

      } else {
        //-->
      }
    } else {

      QUICK_SAVE();
    }

  }

  onEditMode = () => {
    if (!this.state.editMode) {
      this.setState({ editMode: true }, () => {
        //Inscription de l'id la pile
        (this.props.APP_GLOBAL_FUNCTION.onEditNoteMode as Function)(
          this.props.note.idnote
        );

        if (this.ref_title.current !== null) {
          this.ref_title.current.value = this.props.note.title
        }
      });
    } else {

      if (
        this.ref_rte.current !== null &&
        new Blob([this.ref_rte.current.getHtml().toString()]).size / 1000000 >
        this.props.APP_GLOBAL_DATA.noteMaxSize
      ) {
        let msgOption: I_MSG_OPTION = {
          allMSG: [
            {
              key: "Taille maximale de saisie dépassée...",
              value:
                "Vous avez atteint la taille maximale de saisie dans la note, limitée à " +
                this.props.APP_GLOBAL_DATA.noteMaxSize +
                "Kb.",
              data: ENU_ICON_TYPE.note,
            },
          ],
          showCloseButton: false,
          buttons: [
            {
              title: "OK",
              F_BUTTON_ACTION: () => {
                (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
              },
            },
          ],
        };

        (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(msgOption);
      } else {
        if (this.ref_rte.current !== null || this.ref_custFom.current !== null) {
          let tmpNote: I_NOTE_ITEM = this.props.note;

          //Nettoyage
          tmpNote.title = this.state.titleValue;

          if (IsCustForm(this.props.note.description)) {
            if (this.ref_custFom.current !== null) {

              this.ref_custFom.current.onConvertFormToString(false);


              if (IsCustForm(this.props.note.description)) {
                tmpNote.title = NOTE_CUST_FORM_EMPTY_TITLE + NOTE_CUST_FORM_EMPTY_PARENT.replace("PARENTNOTEID", GetNoteCustFormData(this.props.note.description, this.props.note.idnote).id.toString()) +
                  this.state.titleValue.replace(NOTE_CUST_FORM_EMPTY_TITLE + NOTE_CUST_FORM_EMPTY_PARENT.replace("PARENTNOTEID", GetNoteCustFormData(this.props.note.description, this.props.note.idnote).id.toString()), "");
              } else {
                tmpNote.title = this.state.titleValue;
              }
              tmpNote.description = this.ref_custFom.current.state.tmpBodyContener;
              tmpNote.descriptionraw = this.ref_custFom.current.state.tmpBodyContener;

            }
          } else if (this.ref_rte.current !== null) {
            tmpNote.description = this.ref_rte.current.getHtml();
            tmpNote.descriptionraw = this.ref_rte.current.getHtml();
          }


          tmpNote.modified = GetFormatedDate(new Date(), true);
          //---------

          (this.props.APP_GLOBAL_FUNCTION.updateNote as Function)(
            tmpNote,
            this.state.cash_Tasks,
            tmpNote.displayUnity,
            undefined,
            undefined,
            false
          );

          //Inscription de l'id la pile
          (this.props.APP_GLOBAL_FUNCTION.onEditNoteMode as Function)(
            this.props.note.idnote
          );
        }

        if (
          this.ref_comptaForm.current !== null &&
          IsComptaForm(this.props.note.description)
        )
          this.ref_comptaForm.current.onGenerateNoteBody();
        //Fermeture
        this.setState({ editMode: false }, () => {
          (
            this.props.APP_GLOBAL_FUNCTION.onClearAttachmentsMemorry as Function
          )(this.props.note.idnote);
        });
      }
    }
  }

  onPinningNote = () => {
    this.setState(
      {
        onLoad: true,
      },
      () => {
        (this.props.APP_GLOBAL_FUNCTION.piningNote as Function)(
          this.props.note.idnote,
          !this.props.note.pined
        );
      }
    );
  }

  onCheckTask = (idTask: number, finished: boolean) => {
    this.setState({ onLoad: true }, () => {
      (this.props.APP_GLOBAL_FUNCTION.checkTaskNote as Function)(
        idTask,
        finished
      );
    });
  }

  componentDidUpdate(
    prevProps: Readonly<I_NOTE_ITEM_PROPS>,
    prevState: Readonly<I_NOTE_ITEM_PROPS_STATE>,
    snapshot?: any
  ): void {
    try {
      setTimeout(() => {
        if (
          prevProps.attachments !== undefined &&
          (prevProps.attachments as I_ATTACHMENT_LINK_ITEM[]).length !==
          (this.props.attachments as I_ATTACHMENT_LINK_ITEM[]).length &&
          !this.state.editMode
        ) {
          (this.props.APP_GLOBAL_FUNCTION.loadAsyncAttachment as Function)(
            this.props.note.idnote
          );
        }




        //Mise à jour des taches
        if (this.props.tasks !== undefined && (this.props.tasks as I_TASK_ITEM[]) !== this.state.cash_Tasks) {
          this.setState({ cash_Tasks: this.props.tasks as I_TASK_ITEM[] });
        }

        // if (this.state.onLoad) this.setState({ onLoad: false });

        if (this.props.note.description.length === 0 && this.props.APP_GLOBAL_DATA.openedNote.filter(on => on === this.props.note.idnote).length > 0) {
          (this.props.APP_GLOBAL_FUNCTION.onLoadNoteData as Function)(this.props.note.idnote, false);

        }

        //Suppression des notifications si consulté
        if (this.state.open) {
          if (
            this.props.APP_GLOBAL_DATA.allNotifs.filter(
              (n) => n.noteId === (this.props.note.idnote as number)
            ).length > 0
          ) {
            const idNotif: number = this.props.APP_GLOBAL_DATA.allNotifs.filter(
              (n) => n.noteId === (this.props.note.idnote as number)
            )[0].idNotif;
            (this.props.APP_GLOBAL_FUNCTION.onReadNotifElement as Function)(
              idNotif
            );
          }

        }
      }, 1000);
    } finally {
      //-->
    }

  }

  componentDidMount() {


    if (this.props.note.title === "..." && this.props.note.isAuthor === 1 && !this.state.editMode && !this.state.open) {
      this.setState({ editMode: true, open: true }, () => {
        //Focus sur le champ titre
        if (this.ref_title.current !== undefined) {
          this.ref_title.current?.focus();
        }
      });
    }

    this.getAllNoteComents();


    // if (
    //   this.props.APP_GLOBAL_DATA.openedNote.filter(
    //     (on) => on === this.props.note.idnote
    //   ).length > 0
    // ) {
    //   this.setState({ open: true });
    // }

    this.onShowIt();



  }


  //============================

  render(): JSX.Element {

    try {
      return (

        <div
          className={this.state.open ? "NI_ZONE NI_ZONE_SELECTED" : "NI_ZONE"}
          style={{
            border: "2px solid #c3c3c3",
            borderStyle: this.state.editMode ? "dotted" : "solid",
            borderWidth: this.state.editMode ? 2 : 1,
            height: this.state.open ? "100%!important" : "0%!important",
            opacity: this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote && nav.isNoteOpened).length > 0 ? 1 : 0,
          }}
          id={this.props.note.idnote.toString()}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.ctrlKey && e.key === "s" && this.state.editMode) {
              e.preventDefault();
              this.onQuickSaveNote(e);
            }
          }}


          onMouseLeave={(e) => {
            if (this.state.editMode)
              this.onQuickSaveNote()
          }}

          onDoubleClick={() => {
            if (this.state.canBeShown && !this.state.editMode) {
              let msgOption: I_MSG_OPTION = {
                allMSG: [
                  {
                    key: "Editer la note ?",
                    value: "Voulez-vous mettre la note en mode édition ?",
                    data: ENU_ICON_TYPE.edit
                  }
                ],
                showCloseButton: true,
                buttons: [
                  {
                    title: "Editer la note",
                    F_BUTTON_ACTION: () => {
                      this.onEditMode();
                      (this.props.APP_GLOBAL_FUNCTION.closePopup as Function)();
                    }
                  }
                ]
              };

              (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(msgOption);
            }
          }}
        >

          <div id={this.props.note.idnote + "-NOTEHEADER"}></div>

          {this.state.onLoad ||
            (this.props.note.description.length === 0 &&
              this.state.open &&
              this.props.note.descriptionraw.length === 0 &&
              this.props.APP_GLOBAL_DATA.openedNote.filter(
                (op) => op === this.props.note.idnote
              ).length > 0 && (
                <LAYOUT_LOADER message="Chargement en cours ..." />
              ))}

          {/* Markbook */}
          {this.props.note.pined && (
            <span className="NI_MARKBOOK">
              <span
                dangerouslySetInnerHTML={{
                  __html: GetIconFromType(
                    ENU_ICON_TYPE.markBook,
                    24,
                    this.props.typesNotes.filter(
                      (tn) => tn.id === this.props.note.type
                    ).length > 0
                      ? this.props.typesNotes.filter(
                        (tn) => tn.id === this.props.note.type
                      )[0].colorString
                      : ""
                  ),
                }}
              />
            </span>
          )}
          {/* ============== */}



          {this.props.APP_GLOBAL_DATA.sharedItems.filter(
            (si) => si.idNote !== null && si.idNote === this.props.note.idnote
          ).length > 0 && (
              <span
                className="NI_COMMUNITY_INDICATOR"
                dangerouslySetInnerHTML={{
                  __html: GetIconFromType(ENU_ICON_TYPE.community, 16),
                }}
              ></span>
            )}

          <span
            className="NI_LAYOUT"
            style={{
              backgroundColor:
                this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined ?
                  "#" +
                  (this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(
                    (tn) => tn.id === this.props.note.type
                  ).length > 0
                    ? this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(
                      (tn) => tn.id === this.props.note.type
                    )[0].colorString
                    : "3c3c3c") : "ffffff"
              ,

            }}
          ></span>
          <div
            style={{
              marginLeft: this.props.note.pined ? "14px" : "1px",
              position: "relative",
              top:"2px"
            }}
          >
            {/* Mode vue */}
            {!this.state.editMode
              && (
                this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                  this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                  : false
              ) && (
                <div onClick={() => this.onOpenNote()} title={this.props.APP_GLOBAL_DATA.openedNote.filter(n => n === this.props.note.idnote).length > 0 ? "Cliquez pour ferme la note" : "Cliquez pour consulter la note"}>
                  <div>
                    {IsComptaForm(this.props.note.description) && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: GetIconFromType(ENU_ICON_TYPE.euroDevise, 11),
                        }}
                      ></span>
                    )}
                    {this.props.APP_GLOBAL_DATA.DB_NOTES_VIDEO !== undefined &&
                      this.props.APP_GLOBAL_DATA.DB_NOTES_VIDEO.filter(
                        (v) => v === this.props.note.idnote
                      ).length > 0 && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: GetIconFromType(ENU_ICON_TYPE.video, 11),
                          }}
                        ></span>
                      )}
                    &nbsp;

                    {
                      //Si  non classeur
                      //-----------------
                      <span className="TEXT_TICKET NI_TITLE_LINE" style={{ borderRadius: "7px 7px 0px 0px", opacity: "1" }}>


                        {/* ================================== */}
                        <span>
                          <span className="NOTE_OPENED_INDICATOR"
                            style={{ rotate: this.state.open ? "90deg" : "0deg" }}
                            dangerouslySetInnerHTML={{
                              __html: GetIconFromType(
                                ENU_ICON_TYPE.arrowPath, 14, "EB6B16"
                              )
                            }}>
                          </span>
                          <span className="SPAN_SEP"></span>
                        </span>
                        {/* ================================== */}

                        {
                          IsCustForm(this.props.note.title) &&
                          GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent &&
                          <span>
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_parent, 11) }}>
                            </span>
                            <span className="SPAN_SEP"></span>
                          </span>
                        }
                        {
                          IsCustForm(this.props.note.title) &&
                          !GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent &&
                          <span>
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 11) }}>
                            </span>
                            <span className="SPAN_SEP"></span>
                          </span>
                        }


                        <span>{
                          IsCustForm(this.props.note.title) ?
                            this.props.note.title.split("]-")[1] :
                            this.props.note.title
                        }</span>
                        <span className="NOTE_OPENABLE_CLICK_ZONE">
                          <EYE_DYN
                            allwaysOnTop={false}
                            eye_status={
                              IsComptaForm(this.props.note.description) &&
                                this.props.APP_GLOBAL_DATA.selectedNoteMenu ===
                                ENU_ICON_TYPE.euroDevise
                                ? this.props.APP_GLOBAL_DATA.selectedNoteMenu ===
                                  ENU_ICON_TYPE.euroDevise && this.state.open
                                  ? ENU_EYE_STATUS.open
                                  : ENU_EYE_STATUS.partialyOpen
                                : this.state.open
                                  ? ENU_EYE_STATUS.open
                                  : ENU_EYE_STATUS.close
                            }
                          />
                          {/* <span>
                            {
                              this.state.open &&
                              "Cliquez dans cette zone pour réduire la note ..."
                            }

                            {
                              !this.state.open &&
                              "Cliquez dans cette zone pour charger la note ..."
                            }
                          </span> */}
                        </span>


                      </span>
                    }




                    {/* Note modifiée */}
                    {this.props.APP_GLOBAL_DATA.allNotifs.filter(
                      (notif) =>
                        notif.noteId === this.props.note.idnote && !notif.readed
                    ).length > 0 && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: GetIconFromType(
                              ENU_ICON_TYPE.notif,
                              16,
                              "f30000"
                            ),
                          }}
                        ></span>
                      )}





                    {/* commentaire ajouté */}
                    {this.props.APP_GLOBAL_DATA.allNotifs.filter(
                      (notif) =>
                        this.state.coments.filter(
                          (c) => c.idComent === notif.noteId && notif.readed
                        ).length > 0
                    ).length > 0 && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: GetIconFromType(ENU_ICON_TYPE.talk, 16, "f30000"),
                          }}
                        ></span>
                      )}
                  </div>
                </div>
              )}

            {/* Note lockée */}
            <div className="HEADER_NOTE_ZONE_INFO" style={{
              height:
                (!this.state.editMode &&
                  this.props.APP_GLOBAL_DATA.allLockedNotes.filter(
                    (notif) =>
                      notif.noteId === this.props.note.idnote
                  ).length > 0) ? "45px" : "0px"
            }}>
              {
                !this.state.editMode &&
                this.props.APP_GLOBAL_DATA.allLockedNotes.filter(
                  (notif) =>
                    notif.noteId === this.props.note.idnote
                ).length > 0 && (
                  <div className="LOCK_NOTE_INFO_BOX">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: GetIconFromType(
                          this.props.APP_GLOBAL_DATA.allLockedNotes.filter(
                            (notif) =>
                              notif.noteId === this.props.note.idnote && !notif.locker
                          ).length > 0 ?
                            ENU_ICON_TYPE.lock : ENU_ICON_TYPE.authorItem,
                          16,
                          "f30000"
                        ),
                      }}
                    ></span>
                    <span className="SPAN_SEP"></span>
                    <span>
                      {
                        this.props.APP_GLOBAL_DATA.allLockedNotes.filter(
                          (notif) =>
                            notif.noteId === this.props.note.idnote && !notif.locker
                        ).length > 0 ? "La note est actuellement verouillée " +
                        " ... merci de résessayer plus tard." : "Vous avez vérouillé la note...les autres utilisateurs ne peuvent pas l'éditer."
                      }
                    </span>


                    {
                      this.props.APP_GLOBAL_DATA.allLockedNotes.filter(
                        (notif) =>
                          notif.noteId === this.props.note.idnote && notif.locker
                      ).length > 0 &&
                      <span>
                        <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                          (this.props.APP_GLOBAL_FUNCTION.onDeleteNoteLock as Function)(this.props.note.idnote);
                        }}>Dévérouiller la note</span>
                      </span>
                    }

                  </div>
                )}
            </div>
            {/* Mode édition */}
            {this.state.editMode &&
              (
                this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                  this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                  : false
              ) &&
              !IsComptaForm(this.props.note.description) && (
                <span className="NOTE_TITLE_ZONE">
                  <input
                    disabled={
                      !IsComptaForm(this.props.note.description) ? false : true
                    }
                    ref={this.ref_title}
                    value={IsCustForm(this.props.note.title) ? this.state.titleValue.split("]-")[1] : this.state.titleValue}
                    onChange={(e) => {
                      this.onChangeTitle(e);
                    }}
                    type={"text"}
                  />
                  <span className="FIELD_COUNTER_BOX" style={{
                    color:
                      this.ref_title.current !== null ?
                        (this.ref_title.current.value.length < 20 ? "grey" : this.ref_title.current.value.length < 60 ? "orange" : this.ref_title.current.value.length < 90 ? "white" : "white") : undefined,
                    backgroundColor:
                      this.ref_title.current !== null ?
                        (this.ref_title.current.value.length < 20 ? "white" : this.ref_title.current.value.length < 60 ? "white" : this.ref_title.current.value.length < 90 ? "orange" : "red") : undefined
                  }}>
                    {
                      this.ref_title.current !== null &&
                      this.ref_title.current.value.length
                    }
                  </span>
                </span>
              )}

            {
              //Se mode Form COMPTA
              this.state.editMode &&
              (
                this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                  this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                  : false
              ) &&
              IsComptaForm(this.props.note.description) && (
                <span
                  style={{
                    padding: "14px",
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: GetIconFromType(ENU_ICON_TYPE.euroDevise, 18),
                    }}
                  ></span>
                  &nbsp;
                  <span>
                    Formulaire de saisie et de gestion des comptes personnels
                  </span>
                </span>
              )
            }

            <span
              className="I_NOTE_ITEM_TYPE_INFO"
              onClick={() => {
                this.state.editMode &&
                  (
                    this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                      this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                      : false
                  ) &&
                  (this.props.APP_GLOBAL_FUNCTION.setNoteType as Function)(
                    this.props.note.idnote
                  );
              }}
            >
              {this.props.typesNotes.filter(
                (tn) => tn.id === this.props.note.type
              ).length > 0 && (
                  <span style={{ position: "relative", top: "-3px" }}>

                    {
                      this.props.typesNotes.filter(
                        (tn) => tn.id === this.props.note.type
                      )[0].title.split(CupBoardCode).length > 1 ?
                        this.props.typesNotes.filter(
                          (tn) => tn.id === this.props.note.type
                        )[0].title.split(CupBoardCode)[1] :
                        this.props.typesNotes.filter(
                          (tn) => tn.id === this.props.note.type
                        )[0].title
                    }

                    {
                      this.props.typesNotes.filter(
                        (tn) => tn.id === this.props.note.type
                      )[0].title.split(CupBoardCode).length > 1 &&
                      <span>
                        <span className="SPAN_SEP"></span>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 14) }}></span>
                      </span>
                    }
                  </span>
                )}

              {this.state.editMode && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: GetIconFromType(
                      ENU_ICON_TYPE.edit,
                      16,
                      this.props.typesNotes.filter(
                        (tn) => tn.id === this.props.note.type
                      ).length > 0
                        ? (this.props.typesNotes.filter(
                          (tn) => tn.id === this.props.note.type
                        )[0].colorString as string)
                        : undefined
                    ),
                  }}
                ></span>
              )}
            </span>
          </div>

          {
            //Vérification de l'intégrité du formulaire
            //-----------------------------------------
            IsCustForm(this.props.note.description) &&
            !GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent &&
            this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
            this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n => parseInt(GetNoteCustParentIdFromTitle(this.props.note.title)) === n.idnote).length === 0 &&
            <div className="CUST_FORM_NO_PARENT_BOX">
              <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.information, 32) }}>
              </span>
              <span>
                Le formulaire ne semble être lié à aucun formulaire parent. Si vous continuez à éditer le fomulaire vous perdrez les fonctionnalités relatives : création de formulaires basés sur le modèle / mise à jour du modèle et des enfants / etc ...
              </span>
              <div style={{ textAlign: "center" }}>
                <div className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                  let tmpCurrentNote: I_NOTE_ITEM = this.props.note;

                  tmpCurrentNote.description = tmpCurrentNote.description.replace("FORMID:" + GetNoteCustParentIdFromTitle(this.props.note.title), "FORMID:" + this.props.note.idnote.toString());// tmpCurrentNote.description.replace("-PARENT-["+GetNoteCustParentIdFromTitle(this.props.note.title)+"]-","-PARENT-["+this.props.note.idnote+"]-");
                  tmpCurrentNote.descriptionraw = tmpCurrentNote.description;
                  tmpCurrentNote.title = NOTE_CUST_FORM_PARENT_TITLE.replace("PARENTNOTEID", this.props.note.idnote.toString());

                  //Suppression des taches, si trouvée
                  //-----------------------------------
                  if (this.props.APP_GLOBAL_DATA.DB_TASKS !== undefined) {
                    let tmpAllNoteTask: I_TASK_ITEM[] = this.props.APP_GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.note.idnote);
                    for (let x = 0; x < tmpAllNoteTask.length; x++) {
                      setTimeout(() => {
                        (this.props.APP_GLOBAL_FUNCTION.deleteTask as Function)(tmpAllNoteTask[x], true)
                      }, 1000);
                    }
                  }
                  //-----------------------------------

                  (this.props.APP_GLOBAL_FUNCTION.updateNote as Function)(tmpCurrentNote);
                  (this.props.APP_GLOBAL_FUNCTION.onSelectNoteMenu as Function)(ENU_ICON_TYPE.form_parent);
                }}>
                  Convertir le formulaire enfant en formulaire parent
                </div>

                <div className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                  this.onQuickSaveNote(undefined, ENU_Noteconvertion.toSimpleNote)
                }}>
                  Convertir en note simple
                </div>

                <div className="APP_FOLDER_TABLE_BUTTON">
                  Initialiser avec un formulaire parent
                </div>



              </div>
            </div>
            //-----------------------------------------
          }

          {
            this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
            this.props.APP_GLOBAL_DATA.navigation.filter(nav =>
              nav.noteId === this.props.note.idnote && this.props.note.description.length === 0 && nav.isNoteOpened
            ).length > 0 && this.state.open &&
            <div>
              chargement en cours
            </div>
          }

          <div
            className="I_NOTE_ITEM_MORE_INFO_ZONE"
            style={{
              backgroundColor: "#ffffff",
              opacity: this.state.open ? .8 : .8,
            }}>
            <span
              className="I_NOTE_ITEM_MORE_INFO_DATE"
              style={{ color: "#3c3c3c" }}
              onClick={() => (!this.state.open ? this.onOpenNote() : {})}>
              <span>
                {this.props.note.created}



              </span>
              {this.props.note.autor !== undefined &&
                this.props.note.autor !== null &&
                this.props.note.autor.length > 0 && (
                  <span className="I_NOTE_AUTHOR_EDITOR_INFO">
                    {this.props.note.autor}
                  </span>
                )}
            </span>
            {this.props.note.created !== this.props.note.modified && (
              <span
                className="I_NOTE_ITEM_MORE_INFO_DATE"
                onClick={() => (!this.state.open ? this.onOpenNote() : {})}
              >
                <span>{this.props.note.modified}</span>

                {this.props.note.editor !== null &&
                  this.props.note.editor.length > 0 && (
                    <span className="I_NOTE_AUTHOR_EDITOR_INFO">
                      {this.props.note.editor}
                    </span>
                  )}

                <span className="SPAN_SEP"></span>
                <span>
                  {
                    //Affichage du nombre de jours ou d'heures ou de minutes ou de secondes ....
                    !this.props.APP_GLOBAL_DATA.loading &&
                    !this.props.APP_GLOBAL_DATA.loadingPendingData &&
                    this.props.note.modified !== null && this.props.note.modified.length > 0 &&
                    <span>
                      <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.timeLeft, 16) }}></span>
                      &nbsp;&nbsp;&nbsp;
                      <span dangerouslySetInnerHTML={{ __html: ComputeTimeReminding(GetDateObjectFromString(this.props.note.modified).dateObj) }}>

                      </span>
                    </span>
                  }
                </span>
              </span>

            )}


            {/* Indicateur divers */}
            {!this.state.open && (
              <div className="I_NOTE_CONTEXTUAL_MENU">
                {this.props.attachments !== undefined &&
                  (this.props.attachments as I_ATTACHMENT_LINK_ITEM[]).length >
                  0 && (
                    <span className="I_NOTE_ITEM_INFO_BOX">
                      <span className="I_NOTE_ITEM_COUNT_INFO">
                        {
                          (this.props.attachments as I_ATTACHMENT_LINK_ITEM[])
                            .length
                        }
                      </span>

                      <span
                        style={{ position: "relative", top: "-7px" }}
                        dangerouslySetInnerHTML={{
                          __html: GetIconFromType(ENU_ICON_TYPE.attachment, 16),
                        }}
                      />
                    </span>
                  )}

                {this.props.attachments !== undefined &&
                  (this.props.attachments as I_ATTACHMENT_LINK_ITEM[]).length >
                  0 &&
                  this.props.tasks !== undefined &&
                  (this.props.tasks as I_TASK_ITEM[]).length > 0 &&
                  (this.props.tasks as I_TASK_ITEM[]).filter((t) => t.finished)
                    .length < (this.props.tasks as I_TASK_ITEM[]).length && (
                    <span
                      className="SPAN_SEP"
                      style={{ marginLeft: "7px", marginRight: "7px" }}
                    ></span>
                  )}

                {(this.props.APP_GLOBAL_DATA.DB_NOTES_VIDEO as number[]).filter(
                  (v) => v === this.props.note.idnote
                ).length > 0 && (
                    <span>
                      <span className="I_NOTE_ITEM_INFO_BOX">
                        <span className="I_NOTE_ITEM_COUNT_INFO"></span>

                        <span
                          style={{ position: "relative", top: "-7px" }}
                          dangerouslySetInnerHTML={{
                            __html: GetIconFromType(ENU_ICON_TYPE.video, 16),
                          }}
                        />
                      </span>
                    </span>
                  )}

                {this.props.tasks !== undefined &&
                  (this.props.tasks as I_TASK_ITEM[]).length > 0 &&
                  (this.props.tasks as I_TASK_ITEM[]).filter((t) => t.finished)
                    .length < (this.props.tasks as I_TASK_ITEM[]).length && (
                    <span>
                      <span className="I_NOTE_ITEM_COUNT_INFO">
                        {
                          (this.props.tasks as I_TASK_ITEM[]).filter(
                            (t) => t.finished
                          ).length
                        }
                        <span>/{(this.props.tasks as I_TASK_ITEM[]).length}</span>
                      </span>

                      <span
                        style={{ position: "relative", top: "-7px" }}
                        dangerouslySetInnerHTML={{
                          __html: GetIconFromType(ENU_ICON_TYPE.task, 18),
                        }}
                      />
                    </span>
                  )}
              </div>
            )}

            {/* Contenu de la zone en mode vue */}
            {this.state.open && (
              <div style={{ fontWeight: "initial" }}>
                {
                  //Mode normal
                  !this.props.asLinkMode &&
                  this.props.withEditMenu &&
                  (
                    this.props.APP_GLOBAL_DATA.allLockedNotes.filter(
                      (notif) =>
                        notif.noteId === this.props.note.idnote &&
                        !notif.locker
                    ).length === 0
                  ) &&
                  this.props.note.displayUnity === 1 && (
                    <span className="I_NOTE_CONTEXTUAL_MENU">
                      <CONTEXTUEAL_MENU_ITEM
                        menus={[
                          {
                            icon: GetIconFromType(
                              !this.state.editMode
                                ? ENU_ICON_TYPE.edit
                                : ENU_ICON_TYPE.save,
                              18,
                              this.state.editMode ? "EB6B16" : undefined
                            ),
                            description: !this.state.editMode
                              ? "Mettre la note en mode édition"
                              : "Sauvegarder les changements",
                            F_Menu_Action: () => {
                              this.onEditMode();
                            },
                            subMenu: false
                          },
                          {
                            icon: GetIconFromType(
                              ENU_ICON_TYPE.PutInFront,
                              18,
                              // this.state.editMode ? "EB6B16" : undefined
                            ),
                            description: "Mettre en évidence",
                            F_Menu_Action: () => {
                              (this.props.APP_GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(
                                {
                                  idnote: this.props.note.idnote,
                                  idgroupnote: this.props.note.idgroupnote,
                                  title: this.props.note.title,
                                  associations: this.props.note.associations
                                } as I_NOTE_ITEM
                              )
                            },
                            subMenu: true
                          },
                          {
                            icon: GetIconFromType(
                              ENU_ICON_TYPE.saveAsLocal,
                              16,
                              // this.state.editMode ? "ffffff" : undefined
                            ),
                            description: "Mémoriser",
                            F_Menu_Action: () => {
                              if (!this.state.editMode)
                                (
                                  this.props.APP_GLOBAL_FUNCTION
                                    .onSaveLocalMemory as Function
                                )(this.props.note);
                            },
                            subMenu: true
                          },
                          {
                            icon: GetIconFromType(ENU_ICON_TYPE.attachment, 16),
                            description: "Ajout de pièces jointes",
                            F_Menu_Action: () => {
                              (
                                this.props.APP_GLOBAL_FUNCTION
                                  .uploadFile as Function
                              )(this.props.note);
                            },
                            subMenu: false
                          },
                          (
                            !IsCustForm(this.props.note.title) || (IsCustForm(this.props.note.title) && !GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent) ?
                              {
                                icon: GetIconFromType((!IsCustForm(this.props.note.title) || (IsCustForm(this.props.note.title) && !GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent) ? ENU_ICON_TYPE.linkItemMode : ENU_ICON_TYPE.form_sync), 16),
                                description:
                                  "Activer le mode liaison",
                                F_Menu_Action: () => {
                                  (!IsCustForm(this.props.note.title) ||
                                    (IsCustForm(this.props.note.title) && !GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent)) &&
                                    (
                                      this.props.APP_GLOBAL_FUNCTION
                                        .onLinkItemMode as Function
                                    )(this.props.note);
                                },
                                subMenu: true
                              } : {
                                icon: GetIconFromType(ENU_ICON_TYPE.empty, 16),
                                description:
                                  undefined,
                                F_Menu_Action: () => {
                                  //-->
                                },
                                subMenu: true
                              }
                          ),
                          (
                            (IsCustForm(this.props.note.title) && !GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent) ?
                              {
                                icon: GetIconFromType(ENU_ICON_TYPE.form_sync, 16),
                                description:
                                  "Synchroniser avec le formulaire parent.",
                                F_Menu_Action: () => {
                                  (
                                    this.props.APP_GLOBAL_FUNCTION
                                      .onUpdateAllCustFormChild as Function
                                  )(parseInt(GetNoteCustParentIdFromTitle(this.props.note.title)), this.props.note.idnote)
                                },
                                subMenu: true
                              } : {
                                icon: GetIconFromType(ENU_ICON_TYPE.empty, 16),
                                description:
                                  undefined,
                                F_Menu_Action: () => {
                                  //-->
                                },
                                subMenu: true
                              }
                          )
                          ,
                          {
                            icon: GetIconFromType(
                              ENU_ICON_TYPE.community,
                              16,
                              !(
                                this.props.APP_GLOBAL_DATA
                                  .selectedGroup as I_GROUPE_NOTE_ITEM
                              ).isAuthor
                                ? "E1E1E1"
                                : this.props.APP_GLOBAL_DATA.sharedItems.filter(
                                  (si) =>
                                    si.idNote !== null &&
                                    si.idNote === this.props.note.idnote
                                ).length > 0
                                  ? "EB6B16"
                                  : undefined
                            ),
                            description: "Gestion des partages",
                            F_Menu_Action: () => {
                              if (
                                (
                                  this.props.APP_GLOBAL_DATA
                                    .selectedGroup as I_GROUPE_NOTE_ITEM
                                ).isAuthor
                              )
                                (
                                  this.props.APP_GLOBAL_FUNCTION
                                    .onShowShareBox as Function
                                )(ENU_SHARE_TYPE.note, this.props.note);
                            },
                            subMenu: true
                          },
                          {
                            icon: GetIconFromType(ENU_ICON_TYPE.markBook, 18),
                            description: "Mettre en favoris",
                            F_Menu_Action: () => {
                              this.onPinningNote();
                            },
                            subMenu: false
                          },
                          {
                            icon: GetIconFromType(ENU_ICON_TYPE.copy, 16),
                            description: "Copier / Dupliquer",
                            F_Menu_Action: () => {
                              (
                                this.props.APP_GLOBAL_FUNCTION
                                  .openCopyNoteMode as Function
                              )(this.props.note);
                            },
                            subMenu: true
                          },
                          {
                            icon: GetIconFromType(ENU_ICON_TYPE.newItem, 18),
                            description: "Ajouter une note enfant à la note",
                            F_Menu_Action: () => {
                              // this.onQuickSaveNote();
                              setTimeout(() => {
                                (this.props.APP_GLOBAL_FUNCTION.addNewNoteInGroup as Function)(undefined, this.props.note);
                              }, 300);

                            },
                            subMenu: false
                          },
                          {
                            icon: GetIconFromType(ENU_ICON_TYPE.cupBoard, 18),
                            description: "Ajouter à un ou plusieurs classeurs",
                            F_Menu_Action: () => {
                              (this.props.APP_GLOBAL_FUNCTION.onPutNoteInCupBoard as Function)(this.props.note.idnote, this.props.parentNoteItem)
                            },
                            subMenu: false
                          },
                          {
                            icon: GetIconFromType(ENU_ICON_TYPE.dash, 16),
                            description: "Mettre la note dans la corbeille",
                            F_Menu_Action: () => {
                              (
                                this.props.APP_GLOBAL_FUNCTION
                                  .deleteNote as Function
                              )(this.props.note);
                            },
                            subMenu: true
                          },
                          {
                            icon: GetIconFromType(ENU_ICON_TYPE.goDownOfNote, 16),
                            description:
                              "Accéder au bas de note",
                            F_Menu_Action: () => {
                              GoToElement(this.props.note.idnote + "-NOTEFOOTER");
                            },
                            subMenu: false
                          },
                        ]}
                      />
                    </span>
                  )
                }

                {
                  //Mode corbeille
                  !this.props.asLinkMode &&
                  this.props.withEditMenu &&
                  this.props.note.displayUnity === 0 &&
                  (
                    this.props.APP_GLOBAL_DATA
                      .selectedGroup as I_GROUPE_NOTE_ITEM
                  ).isAuthor && (
                    <span className="I_NOTE_CONTEXTUAL_MENU">
                      <CONTEXTUEAL_MENU_ITEM
                        menus={[
                          {
                            icon: GetIconFromType(ENU_ICON_TYPE.goBack, 16),
                            description: "Annuler les modifications ...",
                            F_Menu_Action: () => {
                              (
                                this.props.APP_GLOBAL_FUNCTION
                                  .updateNote as Function
                              )(this.props.note, undefined, undefined, 1, undefined);
                            },
                            subMenu: false
                          },
                          {
                            icon: GetIconFromType(
                              ENU_ICON_TYPE.delete,
                              16,
                              "EB6B16"
                            ),
                            description: "Supprimer définitivement la note",
                            F_Menu_Action: () => {
                              (
                                this.props.APP_GLOBAL_FUNCTION
                                  .deleteNote as Function
                              )(this.props.note);
                            },
                            subMenu: false
                          },
                        ]}
                      />
                    </span>
                  )
                }

                {
                  //Link Mode
                  this.props.asLinkMode && (
                    <span className="I_NOTE_CONTEXTUAL_MENU">
                      <CONTEXTUEAL_MENU_ITEM
                        menus={[
                          {
                            icon: GetIconFromType(
                              (this.props.initialLinkedItem as number) ===
                                this.props.note.idnote
                                ? ENU_ICON_TYPE.principalItem
                                : this.props.linkedItems !== undefined &&
                                  (this.props.linkedItems as string[]).filter(
                                    (l) => parseInt(l) === this.props.note.idnote
                                  ).length > 0
                                  ? ENU_ICON_TYPE.linkedItem
                                  : ENU_ICON_TYPE.unLinkedItem,
                              16
                            ),
                            description: "Mode liaison activé",
                            F_Menu_Action: () => {
                              (
                                this.props.APP_GLOBAL_FUNCTION
                                  .onLinkItemToNote as Function
                              )(this.props.note.idnote);
                            },
                            subMenu: false
                          },
                        ]}
                      />
                    </span>
                  )
                }

                {/* pièces jointes */}
                {/* ================ */}
                {!this.props.asLinkMode && (
                  <div className="I_NOTE_ATTACHMENTS_ZONE">
                    {this.props.attachments !== undefined &&
                      (this.props.attachments as I_ATTACHMENT_LINK_ITEM[]).map(
                        (a, index) => {
                          return (
                            <span
                              key={"ATT-" + a + index}
                              className="I_NOTE_ATTACHMENT_LINK_BOX"
                            >
                              <span
                                className={"I_NOTE_ATTACHMENT_LINK_ITEM"}
                                onClick={() => {
                                  (
                                    this.props.APP_GLOBAL_FUNCTION
                                      .showAttahment as Function
                                  )(this.props.note, a.id);
                                }}
                              >
                                {a.title}
                              </span>
                              {this.props.note.displayUnity === 1 && (
                                <span
                                  onClick={() =>
                                    (
                                      this.props.APP_GLOBAL_FUNCTION
                                        .deleteAttachment as Function
                                    )(a)
                                  }
                                  className={"I_NOTE_ATTACHMENT_DELETE"}
                                  dangerouslySetInnerHTML={{
                                    __html: GetIconFromType(
                                      ENU_ICON_TYPE.close,
                                      12,
                                      "F44336"
                                    ),
                                  }}
                                ></span>
                              )}
                            </span>
                          );
                        }
                      )}
                  </div>
                )}
                {/* ================ */}



                {/* Note size zone */}
                <div className="NOTE_SIZE_BOX">
                  {
                    //Si la note n'est pas en mode édition et est ouverte
                    this.state.open && !this.state.editMode &&
                    (
                      this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                        this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                        : false
                    ) &&
                    (this.ref_rte.current !== null || this.ref_custFom.current !== null) && (
                      <div
                        className="NOTE_SIZE_VALUE"
                        style={{
                          width:
                            (100 * new Blob([this.ref_rte.current !== null ? this.ref_rte.current.getHtml() as string : this.ref_custFom.current !== null ? this.ref_custFom.current.state.tmpBodyContener : this.props.note.description]).size) /
                            1000000 /
                            this.props.APP_GLOBAL_DATA.noteMaxSize +
                            "%",
                          backgroundColor:
                            new Blob([this.ref_rte.current !== null ? this.ref_rte.current.getHtml() as string : this.ref_custFom.current !== null ? this.ref_custFom.current.state.tmpBodyContener : this.props.note.description]).size /
                              1000000 <
                              this.props.APP_GLOBAL_DATA.noteMaxSize / 3
                              ? "#1e7710"
                              : new Blob([this.ref_rte.current !== null ? this.ref_rte.current.getHtml() as string : this.ref_custFom.current !== null ? this.ref_custFom.current.state.tmpBodyContener : this.props.note.description]).size /
                                1000000 <
                                (this.props.APP_GLOBAL_DATA.noteMaxSize / 3) * 2
                                ? "#bc6f21"
                                : "#89260d",
                          // width:
                          //   (100 * new Blob([this.props.note.description]).size) /
                          //   1000000 /
                          //   this.props.APP_GLOBAL_DATA.noteMaxSize +
                          //   "%",
                          // backgroundColor:
                          //   new Blob([this.props.note.description]).size /
                          //     1000000 <
                          //     this.props.APP_GLOBAL_DATA.noteMaxSize / 3
                          //     ? "#1e7710"
                          //     : new Blob([this.props.note.description]).size /
                          //       1000000 <
                          //       (this.props.APP_GLOBAL_DATA.noteMaxSize / 3) * 2
                          //       ? "#bc6f21"
                          //       : "#89260d",
                        }}
                      ></div>
                    )
                  }

                  {
                    //Si la note est en mode édition et est ouverte
                    this.state.open &&
                    (
                      this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                        this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                        : false
                    ) &&
                    this.ref_rte.current !== null && (
                      <div
                        className="NOTE_SIZE_VALUE"
                        style={{
                          width:
                            (100 *
                              new Blob([
                                this.ref_rte.current.getHtml().toString(),
                              ]).size) /
                            1000000 /
                            this.props.APP_GLOBAL_DATA.noteMaxSize +
                            "%",
                          backgroundColor:
                            new Blob([
                              this.ref_rte.current.getHtml().toString(),
                            ]).size /
                              1000000 <
                              this.props.APP_GLOBAL_DATA.noteMaxSize / 3
                              ? "#1e7710"
                              : new Blob([
                                this.ref_rte.current.getHtml().toString(),
                              ]).size /
                                1000000 <
                                (this.props.APP_GLOBAL_DATA.noteMaxSize / 3) * 2
                                ? "#bc6f21"
                                : "#89260d",
                        }}
                      ></div>
                    )
                  }

                  <div
                    className="NOTE_SIZE_INFO_BOX"
                    style={{
                      backgroundColor:
                        new Blob([
                          this.state.editMode && this.ref_rte.current !== null
                            ? this.ref_rte.current.getHtml().toString()
                            : this.props.note.description,
                        ]).size /
                          1000000 <
                          this.props.APP_GLOBAL_DATA.noteMaxSize / 3
                          ? "#1e7710"
                          : new Blob([
                            this.state.editMode && this.ref_rte.current !== null
                              ? this.ref_rte.current.getHtml().toString()
                              : this.props.note.description,
                          ]).size /
                            1000000 <
                            (this.props.APP_GLOBAL_DATA.noteMaxSize / 3) * 2
                            ? "#bc6f21"
                            : "#89260d",
                    }}
                  >
                    {/* <span>
                      {(
                        ((new Blob([
                          this.state.editMode && this.ref_rte.current !== null
                            ? this.ref_rte.current.getHtml().toString()
                            : this.props.note.description,
                        ]).size /
                          1000000) *
                          100) /
                        this.props.APP_GLOBAL_DATA.noteMaxSize
                      ).toFixed(2)}
                    </span>
                    <span>%</span> */}
                  </div>
                </div>

                {
                  //Affichage du loader si note non entièrement chargée
                  //---------------------------------------------------
                  this.state.open &&

                  (this.props.note.canBeShown !== undefined && this.props.note.canBeShown) &&
                  (this.props.note.description === undefined ||
                    this.props.note.description === null) &&
                  <LOADER_BASIC />
                }

                {/* Mode Vue de la note */}
                {!this.state.editMode &&
                  (
                    this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                      this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                      : false
                  ) &&
                  this.props.note.description.length > 0 &&
                  !IsComptaForm(this.props.note.description) &&
                  !IsCustForm(this.props.note.title) && (
                    <div className="NOTE_CONTENTE_BOX"
                      title={
                        (
                          new Blob([this.props.note.description]).size / 1000000
                        ).toFixed(2) +
                        "/" +
                        this.props.APP_GLOBAL_DATA.noteMaxSize +
                        "Mb"
                      }
                      style={{height:this.state.open? "auto":"0px"}}
                      dangerouslySetInnerHTML={{
                        __html: ConvertVideoURL(this.props.note.description),
                      }}
                    />
                  )}

                {/* Mode Vue de la note */}
                {this.props.note.description.length > 0 &&
                  (
                    this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                      this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                      : false
                  ) &&
                  IsComptaForm(this.props.note.description) && (
                    <div>
                      <COMPTA_FORM
                        ref={this.ref_comptaForm}
                        APP_DATA={this.props.APP_GLOBAL_DATA}
                        APP_FUNCTION={this.props.APP_GLOBAL_FUNCTION}
                        note={this.props.note}
                        editMode={this.state.editMode}
                        showTotalOnly={!this.state.open}
                      />
                    </div>
                  )}

                {/* Mode vude de la note */}
                {
                  this.props.note.description.length > 0 &&
                  (
                    this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                      this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                      : false
                  ) &&
                  IsCustForm(this.props.note.description) &&
                  <CUST_FORM_BOX computeSugestion={this.onComputeSugestion} canEditInLive={
                    this.props.note.isAuthor === 1 ||
                    (this.props.APP_GLOBAL_DATA.sharedItemsWithMe.filter(si =>
                      (
                        (
                          this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                          this.props.APP_GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                          si.idType === undefined &&
                          si.idNote === undefined
                        )
                        ||
                        (
                          this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                          this.props.APP_GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                          si.idType !== undefined &&
                          this.props.note.type === si.idType &&
                          si.idNote === undefined
                        )
                        ||
                        (
                          this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                          this.props.APP_GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                          si.idType !== undefined &&
                          this.props.note.type === si.idType &&
                          si.idNote !== undefined && this.props.note.idnote
                        )
                      )
                      && si.canEdit
                    ).length > 0)

                  } ref={this.ref_custFom} note={this.props.note} editMode={this.state.editMode} GLOBAL_DATA={this.props.APP_GLOBAL_DATA} GLOBAL_FUNCTION={this.props.APP_GLOBAL_FUNCTION} />
                }

                {/* Mode Edition */}
                {
                  this.state.editMode &&
                  (
                    this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                      this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                      : false
                  ) &&
                  this.props.note.description !== undefined &&
                  !IsComptaForm(this.props.note.description) &&
                  !IsCustForm(this.props.note.description) &&
                  !IsCustForm(this.props.note.title) && (
                    <div
                      className="NOTE_CONTAINER_BOX"

                      title={
                        (
                          new Blob([this.props.note.description]).size / 1000000
                        ).toFixed(2) + "Mb"
                      }
                    >
                      <RichTextEditorComponent

                        onClick={(e) => {
                          console.log("mouse location:", e.clientX, e.clientY)
                        }}



                        autoSaveOnIdle={true}


                        ref={this.ref_rte}
                        toolbarSettings={{
                          items: [
                            "Bold",
                            "Italic",
                            "Underline",
                            "StrikeThrough",
                            "FontName",
                            "FontSize",
                            "FontColor",
                            "BackgroundColor",
                            "LowerCase",
                            "UpperCase",
                            "|",
                            "Formats",
                            "Alignments",
                            "OrderedList",
                            "UnorderedList",
                            "Outdent",
                            "Indent",
                            "|",
                            "CreateLink",
                            "Image",
                            "|",
                            "ClearFormat",
                            "Print",
                            "SourceCode",
                            "|",
                            "Undo",
                            "Redo",
                          ],
                        }}
                        insertImageSettings={{
                          saveFormat: "Base64",
                        }}


                      >
                        {/* INTERNAL MESSAGE */}
                        <div id="INTERNAL_MESSAGE"></div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.props.note.description,
                          }}
                        ></p>
                        {/* <div id="INTERNAL_MESSAGE"></div> */}
                        {/* INTERNAL MESSAGE */}

                        <Inject
                          services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]}
                        />
                      </RichTextEditorComponent>
                    </div>
                  )
                }

                <div id={this.props.note.idnote + "-NOTEFOOTER"}></div>

                {/* Note size zone */}
                <div className="NOTE_SIZE_BOX">
                  {
                    //Si la note n'est pas en mode édition et est ouverte
                    this.state.open && !this.state.editMode && (
                      <div
                        className="NOTE_SIZE_VALUE"
                        style={{
                          width:
                            (100 * new Blob([this.props.note.description]).size) /
                            1000000 /
                            this.props.APP_GLOBAL_DATA.noteMaxSize +
                            "%",
                          backgroundColor:
                            new Blob([this.props.note.description]).size /
                              1000000 <
                              this.props.APP_GLOBAL_DATA.noteMaxSize / 3
                              ? "#1e7710"
                              : new Blob([this.props.note.description]).size /
                                1000000 <
                                (this.props.APP_GLOBAL_DATA.noteMaxSize / 3) * 2
                                ? "#bc6f21"
                                : "#89260d",
                        }}
                      ></div>
                    )
                  }

                  {
                    //Si la note est en mode édition et est ouverte
                    this.state.open &&
                    this.state.editMode &&
                    (
                      this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                        this.props.APP_GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                        : false
                    ) &&
                    this.ref_rte.current !== null && (
                      <div
                        className="NOTE_SIZE_VALUE"
                        style={{
                          width:
                            (100 *
                              new Blob([
                                this.ref_rte.current.getHtml().toString(),
                              ]).size) /
                            1000000 /
                            this.props.APP_GLOBAL_DATA.noteMaxSize +
                            "%",
                          backgroundColor:
                            new Blob([
                              this.ref_rte.current.getHtml().toString(),
                            ]).size /
                              1000000 <
                              this.props.APP_GLOBAL_DATA.noteMaxSize / 3
                              ? "#1e7710"
                              : new Blob([
                                this.ref_rte.current.getHtml().toString(),
                              ]).size /
                                1000000 <
                                (this.props.APP_GLOBAL_DATA.noteMaxSize / 3) * 2
                                ? "#bc6f21"
                                : "#89260d",
                        }}
                      ></div>
                    )
                  }

                  <div
                    className="NOTE_SIZE_INFO_BOX"
                    style={{
                      backgroundColor:
                        new Blob([
                          this.state.editMode && this.ref_rte.current !== null
                            ? this.ref_rte.current.getHtml().toString()
                            : this.props.note.description,
                        ]).size /
                          1000000 <
                          this.props.APP_GLOBAL_DATA.noteMaxSize / 3
                          ? "#1e7710"
                          : new Blob([
                            this.state.editMode && this.ref_rte.current !== null
                              ? this.ref_rte.current.getHtml().toString()
                              : this.props.note.description,
                          ]).size /
                            1000000 <
                            (this.props.APP_GLOBAL_DATA.noteMaxSize / 3) * 2
                            ? "#bc6f21"
                            : "#89260d",
                    }}
                  >
                    {/* <span>
                      {(
                        ((new Blob([
                          this.state.editMode && this.ref_rte.current !== null
                            ? this.ref_rte.current.getHtml().toString()
                            : this.props.note.description,
                        ]).size /
                          1000000) *
                          100) /
                        this.props.APP_GLOBAL_DATA.noteMaxSize
                      ).toFixed(2)}
                    </span>
                    <span>%</span> */}
                  </div>
                </div>

                {
                  // Tous les tags de la note
                  <div className="I_NOTE_TAG_ZONE">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: GetIconFromType(ENU_ICON_TYPE.tag, 16),
                      }}
                    ></span>
                    {
                      //Tous les tags
                      this.props.note.tags
                        .split(";")
                        .filter((t) => t !== "")
                        .map((t, index) => {
                          return (
                            <span key={index} className="I_NOTE_TAG_ITEM">
                              <span>{t}</span>
                              {this.props.withEditMenu && (
                                <span
                                  onClick={() => {
                                    this.onDeleteTag(t);
                                  }}
                                  className="I_NOTE_TAG_ITEM_DELETEBUTTON"
                                  dangerouslySetInnerHTML={{
                                    __html: GetIconFromType(
                                      ENU_ICON_TYPE.close,
                                      7,
                                      "F44336"
                                    ),
                                  }}
                                ></span>
                              )}
                            </span>
                          );
                        })
                    }

                    {/* Les propositions de tags */}
                    {this.props.withEditMenu &&
                      this.props.note.displayUnity === 1 && (
                        <span className="I_NOTE_TAG_NEW">
                          <input
                            title="Saisir un tag pour la note"
                            placeholder="..."
                            ref={this.ref_fieldTag}
                            type="text"
                            onChange={(e) =>
                              (!IsComptaForm(this.props.note.description)) &&
                              this.onChangeTagTmpValue(e)

                            }
                            onKeyDown={(e) => {
                              this.onNavigateOnExistedTags(
                                e,
                                this.state.tagTmpValue
                              );
                            }}
                            value={
                              this.props.tags.length > 0 &&
                                this.state.selectedTagIndex > -1
                                ? this.props.tags
                                  .filter(
                                    (_t) =>
                                      _t !== "" &&
                                      _t
                                        .toUpperCase()
                                        .indexOf(
                                          this.state.tagTmpValue.toUpperCase()
                                        ) > -1
                                  )
                                  .sort((a, b) => {
                                    return a.localeCompare(b);
                                  })
                                  .slice(0, 10)[this.state.selectedTagIndex]
                                : this.state.tagTmpValue
                            }
                          ></input>

                          {/* les propositions */}
                          {this.props.tags !== undefined &&
                            this.props.tags.length > 0 &&
                            this.state.tagTmpValue !== undefined &&
                            this.props.tags
                              .filter(
                                (_t) =>
                                  _t !== "" &&
                                  _t
                                    .toUpperCase()
                                    .indexOf(
                                      this.state.tagTmpValue.toUpperCase()
                                    ) > -1
                              )
                              .sort((a, b) => {
                                return a.localeCompare(b);
                              })
                              .slice(0, 10).length > 0 &&
                            this.state.tagTmpValue.length > 0 && (
                              <div className="I_NOTE_TAG_LIST_ZONE">
                                {this.props.tags
                                  .filter(
                                    (_t) =>
                                      _t !== "" &&
                                      _t
                                        .toUpperCase()
                                        .indexOf(
                                          this.state.tagTmpValue.toUpperCase()
                                        ) > -1
                                  )
                                  .sort((a, b) => {
                                    return a.localeCompare(b);
                                  })
                                  .slice(0, 10)
                                  .map((_t, index) => {
                                    return (
                                      <span
                                        onClick={() => {
                                          this.onSelectTag(_t);
                                        }}
                                        style={{ display: "block" }}
                                        key={_t}
                                        className={
                                          this.state.selectedTagIndex === index
                                            ? "I_NOTE_TAG_ITEM I_NOTE_TAG_ITEM_OVER_FILTERED"
                                            : "I_NOTE_TAG_ITEM"
                                        }
                                      >
                                        {_t}
                                      </span>
                                    );
                                  })}
                              </div>
                            )}
                        </span>
                      )}
                  </div>
                }

                {
                  //Tous les tags proposés
                  this.state.open &&
                  this.state.editMode &&
                  (this.props.APP_GLOBAL_DATA.DB_TAGS as string[]).filter(
                    (_t) =>
                      _t.length > 0 &&
                      this.props.note.tags
                        .split(";")
                        .filter((nt) => nt.length > 0 && nt === _t).length ===
                      0 &&
                      this.ref_rte.current !== null &&
                      this.ref_rte.current
                        .getText()
                        .toUpperCase()
                        .indexOf(_t.toUpperCase()) > -1
                  ).length > 0 && (
                    <div className="I_NOTE_TAG_ZONE I_NOTE_TAG_ZONE_PROPOSITIONS">
                      <div>
                        Cliquez sur le tag pour l'ajouter à la note
                        ...
                      </div>
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: GetIconFromType(
                              ENU_ICON_TYPE.tag,
                              16,
                              "c3c3c3"
                            ),
                          }}
                        ></span>
                        {(this.props.APP_GLOBAL_DATA.DB_TAGS as string[])
                          .filter(
                            (_t) =>
                              _t.length > 0 &&
                              this.props.note.tags
                                .split(";")
                                .filter((nt) => nt.length > 0 && nt === _t)
                                .length === 0 &&
                              this.ref_rte.current !== null &&
                              this.ref_rte.current
                                .getText()
                                .toUpperCase()
                                .indexOf(_t.toUpperCase()) > -1
                          )
                          .map((_t) => {
                            return (
                              <span
                                key={_t}
                                className="I_NOTE_TAG_ITEM"
                                onClick={() => {
                                  this.onSelectTag(_t);
                                }}
                              >
                                <span>{_t}</span>
                              </span>
                            );
                          })}
                      </div>
                    </div>
                  )
                }



                {
                  //Toute les note sugérées 
                  //========================
                  this.state.open &&
                  this.state.editMode &&
                  this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
                  <div className="NOTE_SUGESTION_BOX">
                    <div style={{ fontSize: "12px", fontStyle: "italic", fontWeight: "bold", position: "relative", paddingLeft: "14px", paddingTop: "14px" }}>
                      <span>{
                        this.state.currentSugestion.length + " / " + (this.state.sugestionMaxValue) + " sugestion(s) trouvée(s)"
                      }</span>
                      <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                        this.onComputeSugestion();
                      }}>Lancer la recherche</span>
                    </div>
                    <div className="NOTE_SUGESTION_SEARCH_ZONE">
                      <span><SEARCHBOX ref={this.ref_sugestion} placeHolder="Recherche par mot clé ..."
                        F_UPDATE_SEARCHVALUE={() => {

                        }}
                        forceUpdateEveryTime={true} APPFUNCTION={this.props.APP_GLOBAL_FUNCTION} /></span>
                      <span>
                        {
                          this.state.currentSugestion
                            .filter(n => this.ref_sugestion.current !== null && n.title.toUpperCase().indexOf(this.ref_sugestion.current.state.searchValue.toUpperCase()) > -1).length
                        }
                      </span>
                    </div>

                    {
                      this.state.currentSugestion.length > 0 &&
                      <div className="NOTE_SUGESTION_RESULTS_ZONE">
                        {
                          this.state.sugestionMaxValue &&
                          this.state.currentSugestion
                            .filter(n =>
                              this.ref_sugestion.current !== null && n.title.toUpperCase().indexOf(this.ref_sugestion.current.state.searchValue.toUpperCase()) > -1
                            )
                            .map(n => {
                              return <span className="NOTE_SUGESTION_ITEM_BOX" style={{
                                backgroundColor:
                                  this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined && this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                    tn.id === n.type).length > 0 ?
                                    "#" + this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn =>
                                      tn.id === n.type)[0].colorString : "initial"
                              }} onClick={() => {
                                this.onShowSugestion(n)
                              }}>
                                <span className="TEXT_TICKET">
                                  {
                                    n.title
                                  }
                                </span>
                              </span>
                            })
                        }

                        <span className="NOTE_SUGESTION_ITEM_BOX" onClick={() => {
                          this.onChangeMaxSugestionValue(true);
                        }} title="Afficher plus de sugestions...">
                          <span className="TEXT_TICKET">
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.plus, 14) }}>

                            </span>
                          </span>
                        </span>

                        {
                          this.state.sugestionMaxValue > 30 &&
                          <span className="NOTE_SUGESTION_ITEM_BOX" onClick={() => {
                            this.onChangeMaxSugestionValue(false);
                          }} title="Afficher moins de sugestions...">
                            <span className="TEXT_TICKET">
                              <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.less, 14) }}>

                              </span>
                            </span>
                          </span>
                        }

                      </div>
                    }

                  </div>
                }

                <div className="I_NOTE_SAVE_LINE">
                  {this.props.typesNotes !== undefined &&
                    !this.props.asLinkMode &&
                    this.props.withEditMenu &&
                    (
                      this.props.APP_GLOBAL_DATA.allLockedNotes.filter(
                        (notif) =>
                          notif.noteId === this.props.note.idnote &&
                          !notif.locker
                      ).length === 0
                    ) &&
                    this.props.note.displayUnity === 1 && (
                      <CONTEXTUEAL_MENU_ITEM
                        menus={
                          [
                            {
                              icon: this.state.editMode
                                ? GetIconFromType(ENU_ICON_TYPE.save, 16, "EB6B16")
                                : GetIconFromType(ENU_ICON_TYPE.edit, 16),
                              description: this.state.editMode ? "Sauvegarder les changements" : "Entrer en mode édition",
                              F_Menu_Action: () => {
                                this.onEditMode();
                              },
                              subMenu: false
                            },
                            {
                              icon: GetIconFromType(ENU_ICON_TYPE.addStack, 16),
                              description: "Ajouter une nouvelle tâche",
                              F_Menu_Action: () => {
                                (
                                  this.props.APP_GLOBAL_FUNCTION
                                    .addNewTaskInNoteGroup as Function
                                )(this.props.note);
                              },
                              subMenu: false
                            },
                            {
                              icon: GetIconFromType(ENU_ICON_TYPE.goTopOfNote, 16),
                              description: "Remonter en haut de la note",
                              F_Menu_Action: () => {
                                GoToElement(this.props.note.idnote + "-NOTEHEADER");
                              },
                              subMenu: false
                            },
                          ] as I_CONTEXTUAL_MENU_ITEM[]
                        }
                      />
                    )}
                </div>
              </div>
            )}



            {/* Mode Vue des formulaires compta */}
            {this.props.note.description.length > 0 &&
              IsComptaForm(this.props.note.description) &&
              !this.state.open &&
              this.props.APP_GLOBAL_DATA.selectedNoteMenu ===
              ENU_ICON_TYPE.euroDevise && (
                <div>
                  <COMPTA_FORM
                    ref={this.ref_comptaForm}
                    APP_DATA={this.props.APP_GLOBAL_DATA}
                    APP_FUNCTION={this.props.APP_GLOBAL_FUNCTION}
                    note={this.props.note}
                    editMode={this.state.editMode}
                    showTotalOnly={!this.state.open}
                  />
                </div>
              )}

            {/* Mode Vue  Video */}
            {!this.state.editMode &&
              this.props.note.description.length > 0 &&
              !IsComptaForm(this.props.note.description) &&
              this.props.APP_GLOBAL_DATA.selectedNoteMenu ===
              ENU_ICON_TYPE.video && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: ConvertVideoURL(this.props.note.description),
                  }}
                />
              )}

            {/* Zone des taches */}
            {(this.props.showTaskNote || this.state.open) && (
              <div className="I_NOTE_TASK">
                {this.props.tasks !== undefined &&
                  (this.props.tasks as I_TASK_ITEM[]).map((t, index) => {
                    return (
                      <div key={index}>

                        {
                          this.props.note.isAuthor !== 1 &&
                          (this.props.APP_GLOBAL_DATA.sharedItemsWithMe.filter(si =>
                            (
                              (
                                this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                this.props.APP_GLOBAL_DATA.selectedGroup.id === si.idGroup
                              )
                              ||
                              (
                                this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                this.props.APP_GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                (si.idType !== undefined &&
                                  this.props.note.type === si.idType)
                              )
                              ||
                              (
                                this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                this.props.APP_GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                (si.idType !== undefined &&
                                  this.props.note.type === si.idType) &&
                                si.idNote !== undefined && si.idNote === this.props.note.idnote
                              )
                            )
                            && si.canEdit
                          ).length === 0) &&
                          <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.lock, 12, "c3c3c3") }} title="Vous ne disposez pas d'autorisations sufisantes pour gérer le statut de la tache..."></span>
                        }

                        {t.finished && (
                          <span
                            onClick={() => {
                              if (
                                this.props.note.isAuthor === 1 ||
                                (this.props.APP_GLOBAL_DATA.sharedItemsWithMe.filter(si =>
                                  (
                                    (
                                      this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                      this.props.APP_GLOBAL_DATA.selectedGroup.id === si.idGroup
                                    )
                                    ||
                                    (
                                      this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                      this.props.APP_GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                      (si.idType !== undefined &&
                                        this.props.note.type === si.idType)
                                    )
                                    ||
                                    (
                                      this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                      this.props.APP_GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                      (si.idType !== undefined &&
                                        this.props.note.type === si.idType) &&
                                      si.idNote !== undefined && si.idNote === this.props.note.idnote
                                    )
                                  )
                                  && si.canEdit
                                ).length > 0)
                              )
                                this.onCheckTask(t.idtask, !t.finished);
                            }}
                            dangerouslySetInnerHTML={{
                              __html: GetIconFromType(
                                ENU_ICON_TYPE.checkedTask,
                                16
                              ),
                            }}
                          ></span>
                        )}

                        {!t.finished && (
                          <span
                            onClick={() => {
                              if (
                                // !this.state.editMode &&
                                this.props.note.displayUnity === 1 &&
                                (
                                  (
                                    this.props.APP_GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                      this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                      siwm.canEdit &&
                                      siwm.idGroup === this.props.APP_GLOBAL_DATA.selectedGroup.id &&
                                      (
                                        (
                                          (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                          (siwm.idType === null || siwm.idType === undefined)
                                        ) ||
                                        (
                                          (siwm.idNote === null || siwm.idNote === undefined) &&
                                          (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                        ) ||
                                        (
                                          (siwm.idNote === null || siwm.idNote === undefined) &&
                                          (siwm.idType === null || siwm.idType === undefined)
                                        )
                                      )
                                    ).length > 0
                                  )
                                  ||
                                  (this.props.APP_GLOBAL_DATA.DB_GROUPS !== undefined &&
                                    this.props.APP_GLOBAL_DATA.DB_GROUPS.filter(g => this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                      g.id === this.props.APP_GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                ) === true
                              )
                                this.onCheckTask(t.idtask, !t.finished);
                            }}
                            dangerouslySetInnerHTML={{
                              __html: GetIconFromType(
                                ENU_ICON_TYPE.unCheckedTask,
                                16
                              ),
                            }}
                          ></span>
                        )}

                        <span
                          onClick={() => {
                            if (
                              !this.state.editMode &&
                              this.props.note.displayUnity === 1 &&
                              (
                                (
                                  this.props.APP_GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                    this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                    siwm.canEdit &&
                                    siwm.idGroup === this.props.APP_GLOBAL_DATA.selectedGroup.id &&
                                    (
                                      (
                                        (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                        (siwm.idType === null || siwm.idType === undefined)
                                      ) ||
                                      (
                                        (siwm.idNote === null || siwm.idNote === undefined) &&
                                        (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                      ) ||
                                      (
                                        (siwm.idNote === null || siwm.idNote === undefined) &&
                                        (siwm.idType === null || siwm.idType === undefined)
                                      )
                                    )
                                  ).length > 0
                                )
                                ||
                                (this.props.APP_GLOBAL_DATA.DB_GROUPS !== undefined &&
                                  this.props.APP_GLOBAL_DATA.DB_GROUPS.filter(g => this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                    g.id === this.props.APP_GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                              ) === true
                            )
                              this.onCheckTask(t.idtask, !t.finished);
                          }}
                        >
                          {!this.state.editMode && <span>
                            {
                              <span>
                                {
                                  t.title.indexOf("]:") > -1 ?
                                    t.title.split("]:")[1] :
                                    t.title
                                }
                              </span>
                            }
                            {/* 
                            {
                              this.props.note.description===undefined &&
                              <span>
                                {
                                  t.title.indexOf("]:") > -1? t.title.split("]:")[1]:t.title
                                }
                              </span>
                            } */}
                          </span>}

                          {this.state.editMode && (
                            <span>

                              {
                                GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.filter(l => t.title.indexOf(l.id) > -1).length > 0 &&
                                <span>
                                  {
                                    t.title.split(":")[1]
                                  }
                                </span>
                              }

                              {
                                GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.filter(l => t.title.indexOf(l.id) > -1).length === 0 &&
                                <input
                                  type="text"
                                  value={
                                    this.state.cash_Tasks.filter(
                                      (ct) => ct.idtask === t.idtask
                                    ).length > 0
                                      ? this.state.cash_Tasks.filter(
                                        (ct) => ct.idtask === t.idtask
                                      )[0].title
                                      : ""
                                  }
                                  onChange={(e) => {
                                    this.onChangeTaskTitle(t.idtask, e);
                                  }}
                                />}

                            </span>
                          )}
                        </span>

                        {
                          //Edition de la  date
                          this.state.editMode && (
                            <div className="I_NOTE_CALENDAR_ZONE">
                              <DatePicker
                                dateFormat={"dd/MM/yyy"}
                                locale={"fr"}
                                selected={
                                  t.taskdate.length > 0
                                    ? GetDateObjectFromString(t.taskdate).dateObj
                                    : undefined
                                }
                                onChange={(date) => {
                                  if (date !== null) {
                                    // console.log(GetDateStringFromObject(date as Date));
                                    (
                                      this.props.APP_GLOBAL_FUNCTION
                                        .updateTaskItem as Function
                                    )(
                                      t,
                                      GetDateStringFromObject(date as Date, false)
                                    );
                                  } else {
                                    // console.log(date);
                                    (
                                      this.props.APP_GLOBAL_FUNCTION
                                        .updateTaskItem as Function
                                    )(t, "");
                                  }
                                }}
                              />
                            </div>
                          )
                        }

                        {
                          //Vue de la date
                          !this.state.editMode && (
                            <div
                              className="I_NOTE_CALENDAR_ZONE"
                              onDoubleClick={() => {
                                if (this.state.open)
                                  this.props.note.displayUnity === 1 &&
                                    this.onEditMode();
                              }}
                            >
                              {t.taskdate.length > 0 ? t.taskdate : "--/--/----"}
                            </div>
                          )
                        }

                        {!this.props.asLinkMode &&
                          this.props.note.displayUnity === 1 &&
                          (
                            (
                              this.props.APP_GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                siwm.canEdit &&
                                siwm.idGroup === this.props.APP_GLOBAL_DATA.selectedGroup.id &&
                                (
                                  (
                                    (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                    (siwm.idType === null || siwm.idType === undefined)
                                  ) ||
                                  (
                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                    (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                  ) ||
                                  (
                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                    (siwm.idType === null || siwm.idType === undefined)
                                  )
                                )
                              ).length > 0
                            )
                            ||
                            (this.props.APP_GLOBAL_DATA.DB_GROUPS !== undefined &&
                              this.props.APP_GLOBAL_DATA.DB_GROUPS.filter(g => this.props.APP_GLOBAL_DATA.selectedGroup !== undefined &&
                                g.id === this.props.APP_GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                          ) === true &&
                          (
                            <span className="I_NOTE_ITEM_CONTEXTUAL_MENU">
                              <CONTEXTUEAL_MENU_ITEM
                                menus={[
                                  {
                                    icon: GetIconFromType(
                                      ENU_ICON_TYPE.close,
                                      12,
                                      "F44336"
                                    ),
                                    F_Menu_Action: () => {
                                      (
                                        this.props.APP_GLOBAL_FUNCTION
                                          .deleteTask as Function
                                      )(t);
                                    },
                                    subMenu: false,
                                    description: "Supprimer la note"
                                  },
                                ]}
                              />
                            </span>
                          )}
                      </div>
                    );
                  })}
              </div>
            )}

            {
              //Liens connexes parents: accès direct
              //-------------------------------------
              !this.props.asChild &&
              (this.props.APP_GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(
                (n) =>
                  n.associations !== null &&
                  n.associations
                    .split(";")
                    .filter((a) => parseInt(a) === this.props.note.idnote)
                    .length > 0
              ).length > 0 && (
                <div
                  className="NI_NOTE_SHARED_INDICATOR"
                  onClick={() => {
                    this.onShowRelatedNotes();
                  }}
                >
                  <div
                    className={
                      this.state.showRelatedNote
                        ? "NI_NOTE_SHARED_INDICATOR_ICON NI_NOTE_SHARED_INDICATOR_ICON_SELECTED"
                        : "NI_NOTE_SHARED_INDICATOR_ICON"
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: GetIconFromType(
                          ENU_ICON_TYPE.reusedNote,
                          16,
                          "3c3c3c"
                        ),
                      }}
                    ></span>
                    <span className="SPAN_SEP"></span>
                    <span>
                      {
                        (
                          this.props.APP_GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[]
                        ).filter(
                          (n) =>
                            n.associations !== null &&
                            n.associations
                              .split(";")
                              .filter(
                                (a) => parseInt(a) === this.props.note.idnote
                              ).length > 0
                        ).length
                      }
                    </span>
                    {this.state.showRelatedNote && (
                      <span>
                        <span className="SPAN_SEP"></span>
                        {(
                          this.props.APP_GLOBAL_DATA.DB_NOTES as I_NOTE_ITEM[]
                        ).filter(
                          (n) =>
                            n.associations !== null &&
                            n.associations
                              .split(";")
                              .filter(
                                (a) => parseInt(a) === this.props.note.idnote
                              ).length > 0
                        ).length > 1 ? (
                          <span>Autres notes connexes</span>
                        ) : (
                          <span>Autre note connexe</span>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              )
            }

            {
              //Liens connexes enfant: accès direct
              //-------------------------------------
              this.props.asChild &&
              this.props.note.associations !== null &&
              this.props.note.associations
                .split(";")
                .filter((a) => a.length > 0 && parseInt(a) > 0).length > 0 && (
                <div
                  className="NI_NOTE_SHARED_INDICATOR"
                  onClick={() => {
                    this.onShowRelatedNotes();
                  }}
                >
                  <div
                    className={
                      "NI_NOTE_SHARED_INDICATOR_ICON NI_NOTE_SHARED_INDICATOR_ICON_SELECTED"
                    }
                  >
                    <span
                      style={{
                        position: "relative",
                        rotate: "180deg",
                        display: "inline-block",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: GetIconFromType(
                          ENU_ICON_TYPE.reusedNote,
                          16,
                          "3c3c3c"
                        ),
                      }}
                    ></span>
                    <span className="SPAN_SEP"></span>
                    <span>
                      {
                        this.props.note.associations
                          .split(";")
                          .filter((a) => a.length > 0 && parseInt(a) > 0).length
                      }
                    </span>
                    {this.state.showRelatedNote && (
                      <span>
                        <span className="SPAN_SEP"></span>
                        {this.props.note.associations
                          .split(";")
                          .filter((a) => a.length > 0).length > 1 ? (
                          <span>Autres notes connexes</span>
                        ) : (
                          <span>Autre note connexe</span>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              )
            }

            {
              //Bandeau de notes connexes
              //=========================
              this.state.showRelatedNote && (
                <div className="NI_NOTE_SHARED_INDICATOR_LIST_ITEM">
                  {!this.props.asChild &&
                    this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
                    this.state.showRelatedNote &&
                    this.props.APP_GLOBAL_DATA.DB_NOTES.filter(
                      (n) =>
                        n.associations !== null &&
                        n.associations
                          .split(";")
                          .filter(
                            (a) =>
                              a.length > 0 &&
                              parseInt(a) === this.props.note.idnote
                          ).length > 0
                    ).map((n) => {
                      return (
                        <div
                          key={n.idnote}
                          onClick={() => {
                            (
                              this.props.APP_GLOBAL_FUNCTION
                                .onLoadNoteAsMemory as Function
                            )(n, this.props.note.idnote);
                          }}
                        >
                          {n.pined && (
                            <span>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: GetIconFromType(
                                    ENU_ICON_TYPE.markBook,
                                    11
                                  ),
                                }}
                              ></span>
                              <span className="SPAN_SEP"></span>
                            </span>
                          )}

                          {(
                            this.props.APP_GLOBAL_DATA
                              .DB_TYPES_NOTES as I_TYPENOTE[]
                          ).filter((tn) => tn.id === n.type).length > 0 && (
                              <span
                                className="HOME_STATE_TYPE_NOTE"
                                style={{
                                  backgroundColor: ("#" +
                                    (
                                      this.props.APP_GLOBAL_DATA
                                        .DB_TYPES_NOTES as I_TYPENOTE[]
                                    ).filter((tn) => tn.id === n.type)[0]
                                      .colorString) as string,
                                }}
                              >
                                <span className="HOME_STATE_TYPE_BACKGROUND">
                                  {
                                    IsTypeNoteIsAnCupBord((
                                      this.props.APP_GLOBAL_DATA
                                        .DB_TYPES_NOTES as I_TYPENOTE[]
                                    ).filter((tn) => tn.id === n.type)[0].title) ?
                                      (this.props.APP_GLOBAL_DATA
                                        .DB_TYPES_NOTES as I_TYPENOTE[]
                                      ).filter((tn) => tn.id === n.type)[0].title.split(CupBoardCode)[1] :
                                      (this.props.APP_GLOBAL_DATA
                                        .DB_TYPES_NOTES as I_TYPENOTE[]
                                      ).filter((tn) => tn.id === n.type)[0].title
                                  }
                                </span>
                              </span>
                            )}
                          <span className="SPAN_SEP"></span>
                          <span>{n.title}</span>

                          {/* les pièces jointes */}
                          {(
                            this.props.APP_GLOBAL_DATA
                              .DB_ATTACHMENT_LINKS as I_ATTACHMENT_LINK_ITEM[]
                          ).filter((l) => l.nodeId === n.idnote).length > 0 && (
                              <span>
                                <span className="SPAN_SEP"></span>
                                <span>
                                  {
                                    (
                                      this.props.APP_GLOBAL_DATA
                                        .DB_ATTACHMENT_LINKS as I_ATTACHMENT_LINK_ITEM[]
                                    ).filter((l) => l.nodeId === n.idnote).length
                                  }
                                </span>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: GetIconFromType(
                                      ENU_ICON_TYPE.attachment,
                                      11
                                    ),
                                  }}
                                ></span>
                              </span>
                            )}

                          {/* Les taches */}
                          {(
                            this.props.APP_GLOBAL_DATA.DB_TASKS as I_TASK_ITEM[]
                          ).filter((t) => t.idnote === n.idnote).length > 0 && (
                              <span>
                                <span className="SPAN_SEP"></span>
                                <span>
                                  {
                                    (
                                      this.props.APP_GLOBAL_DATA
                                        .DB_TASKS as I_TASK_ITEM[]
                                    ).filter(
                                      (t) => t.idnote === n.idnote && t.finished
                                    ).length
                                  }
                                </span>
                                &nbsp;/&nbsp;
                                <span>
                                  {
                                    (
                                      this.props.APP_GLOBAL_DATA
                                        .DB_TASKS as I_TASK_ITEM[]
                                    ).filter((t) => t.idnote === n.idnote).length
                                  }
                                </span>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: GetIconFromType(ENU_ICON_TYPE.task, 11),
                                  }}
                                ></span>
                              </span>
                            )}

                          {
                            // la date
                            <span>
                              <span className="SPAN_SEP"></span>
                              <span>{n.created}</span>

                              <span className="SPAN_SEP"></span>
                              <span style={{ color: "#c3c3c3" }}>
                                {n.modified}
                              </span>
                            </span>
                          }
                        </div>
                      );
                    })}

                  {this.props.asChild &&
                    this.props.note.associations !== null &&
                    this.props.note.associations
                      .split(";")
                      .filter((a) => a.length > 0).length > 0 &&
                    this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
                    this.state.showRelatedNote &&
                    this.props.APP_GLOBAL_DATA.DB_NOTES.filter(
                      (n) =>
                        this.props.note.associations
                          .split(";")
                          .filter((a) => a.length > 0)
                          .filter((a) => a.length > 0 && parseInt(a) === n.idnote)
                          .length > 0
                    ).map((n) => {
                      return (
                        <div
                          key={n.idnote}
                          onClick={() => {
                            (
                              this.props.APP_GLOBAL_FUNCTION
                                .onLoadNoteAsMemory as Function
                            )(n, this.props.note.idnote);
                          }}
                        >
                          {n.pined && (
                            <span>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: GetIconFromType(
                                    ENU_ICON_TYPE.markBook,
                                    11
                                  ),
                                }}
                              ></span>
                              <span className="SPAN_SEP"></span>
                            </span>
                          )}

                          {(
                            this.props.APP_GLOBAL_DATA
                              .DB_TYPES_NOTES as I_TYPENOTE[]
                          ).filter((tn) => tn.id === n.type).length > 0 && (
                              <span
                                className="HOME_STATE_TYPE_NOTE"
                                style={{
                                  backgroundColor: ("#" +
                                    (
                                      this.props.APP_GLOBAL_DATA
                                        .DB_TYPES_NOTES as I_TYPENOTE[]
                                    ).filter((tn) => tn.id === n.type)[0]
                                      .colorString) as string,
                                }}
                              >
                                <span className="HOME_STATE_TYPE_BACKGROUND">
                                  {
                                    IsTypeNoteIsAnCupBord((
                                      this.props.APP_GLOBAL_DATA
                                        .DB_TYPES_NOTES as I_TYPENOTE[]
                                    ).filter((tn) => tn.id === n.type)[0].title) ?
                                      (this.props.APP_GLOBAL_DATA
                                        .DB_TYPES_NOTES as I_TYPENOTE[]
                                      ).filter((tn) => tn.id === n.type)[0].title.split(CupBoardCode)[1] :
                                      (this.props.APP_GLOBAL_DATA
                                        .DB_TYPES_NOTES as I_TYPENOTE[]
                                      ).filter((tn) => tn.id === n.type)[0].title
                                  }
                                </span>
                              </span>
                            )}
                          <span className="SPAN_SEP"></span>
                          <span>{n.title}</span>

                          {/* les pièces jointes */}
                          {(
                            this.props.APP_GLOBAL_DATA
                              .DB_ATTACHMENT_LINKS as I_ATTACHMENT_LINK_ITEM[]
                          ).filter((l) => l.nodeId === n.idnote).length > 0 && (
                              <span>
                                <span className="SPAN_SEP"></span>
                                <span>
                                  {
                                    (
                                      this.props.APP_GLOBAL_DATA
                                        .DB_ATTACHMENT_LINKS as I_ATTACHMENT_LINK_ITEM[]
                                    ).filter((l) => l.nodeId === n.idnote).length
                                  }
                                </span>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: GetIconFromType(
                                      ENU_ICON_TYPE.attachment,
                                      11
                                    ),
                                  }}
                                ></span>
                              </span>
                            )}

                          {/* Les taches */}
                          {(
                            this.props.APP_GLOBAL_DATA.DB_TASKS as I_TASK_ITEM[]
                          ).filter((t) => t.idnote === n.idnote).length > 0 && (
                              <span>
                                <span className="SPAN_SEP"></span>
                                <span>
                                  {
                                    (
                                      this.props.APP_GLOBAL_DATA
                                        .DB_TASKS as I_TASK_ITEM[]
                                    ).filter(
                                      (t) => t.idnote === n.idnote && t.finished
                                    ).length
                                  }
                                </span>
                                &nbsp;/&nbsp;
                                <span>
                                  {
                                    (
                                      this.props.APP_GLOBAL_DATA
                                        .DB_TASKS as I_TASK_ITEM[]
                                    ).filter((t) => t.idnote === n.idnote).length
                                  }
                                </span>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: GetIconFromType(ENU_ICON_TYPE.task, 11),
                                  }}
                                ></span>
                              </span>
                            )}

                          {
                            // la date
                            <span>
                              <span className="SPAN_SEP"></span>
                              <span>{n.created}</span>

                              <span className="SPAN_SEP"></span>
                              <span style={{ color: "#c3c3c3" }}>
                                {n.modified}
                              </span>
                            </span>
                          }
                        </div>
                      );
                    })}
                </div>
              )
            }

            {/* zone des commentaires */}
            {this.state.open && this.props.note.displayUnity === 1 && (
              <div
                title={this.state.comentMode ? "Commenter la note" : "Ajouter un commentaire à la note"}
                className="I_NOTE_COMENT_ZONE"
                onClick={() => {
                  if (!this.state.comentMode) {
                    this.onOpenComentZone();
                  }
                }}
              >
                <div
                  className="I_NOTE_ACTION_BUTTONS"
                  style={{ textAlign: "right" }}
                >
                  {!this.state.comentMode && (
                    <span>
                      {this.state.coments.length > 0 && (
                        <span>{this.state.coments.length}</span>
                      )}

                      <span
                        dangerouslySetInnerHTML={{
                          __html: GetIconFromType(ENU_ICON_TYPE.talk, 16),
                        }}
                      ></span>
                    </span>
                  )}
                  {this.state.comentMode && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: GetIconFromType(ENU_ICON_TYPE.close, 12),
                      }}
                      onClick={() => {
                        this.onOpenComentZone();
                      }}
                    ></span>
                  )}
                </div>
              </div>
            )}
          </div>


          {
            this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
            this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type).length > 0 &&
            IsTypeNoteIsAnCupBord(this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title) &&
            !this.state.open &&
            <CUPBOARD GLOBAL_DATA={this.props.APP_GLOBAL_DATA} GLOBAL_FUNCTION={this.props.APP_GLOBAL_FUNCTION} currentNote={this.props.note} />
          }

          <div>
            {this.state.comentMode && (
              <FIELD_COMENT_BOX
                title="saisissez un commentaire ..."
                required={false}
                APP_GLOBAL_DATA={this.props.APP_GLOBAL_DATA}
                APP_GLOBAL_FUNCTION={this.props.APP_GLOBAL_FUNCTION}
                noteId={this.props.note.idnote}
                showComents={this.state.comentMode}
                PARENT_FUNCTION_DEFAULT={this.getAllNoteComents}
              />
            )}

            {/* tous les commentaires */}
            <div className="I_NOTE_COMENTS_ZONE">
              {/* Tous les commentaires */}
              {/* ===================== */}
              {this.state.open &&
                this.state.coments !== undefined &&
                this.state.coments
                  .sort((a, b) => {
                    return b.idComent - a.idComent;
                  })
                  .map((c) => {
                    return (
                      <div
                        key={c.idComent}
                        className="I_NOTE_COMENTS_CONTENT"
                        onMouseEnter={() => {
                          if (
                            this.props.APP_GLOBAL_DATA.allNotifs.filter(
                              (notif) =>
                                notif.readed && notif.noteId === c.idComent
                            ).length > 0
                          ) {
                            // console.log(
                            //   this.props.APP_GLOBAL_DATA.allNotifs.filter(
                            //     (notif) =>
                            //       notif.readed && notif.noteId === c.idComent
                            //   )[0].idNotif
                            // );

                            (
                              this.props.APP_GLOBAL_FUNCTION
                                .onAddUnreadNotifInPile as Function
                            )(
                              this.props.APP_GLOBAL_DATA.allNotifs.filter(
                                (notif) =>
                                  notif.readed && notif.noteId === c.idComent
                              )[0].idNotif
                            );
                          }
                        }}
                      >
                        <div className="I_NOTE_COMENTS_AUTHOR">
                          <span>
                            <b>{c.author}</b>
                          </span>
                          &nbsp;
                          <span>{c.created}</span>
                          <span className="I_NOTE_COMENTS_CLOSE_BUTTON">
                            {this.props.APP_GLOBAL_DATA.userProfil !==
                              undefined &&
                              (
                                this.props.APP_GLOBAL_DATA
                                  .userProfil as I_USER_PROFIL_ITEM
                              ).idProfil === c.idUP && (
                                <span
                                  onClick={() => {
                                    (
                                      this.props.APP_GLOBAL_FUNCTION
                                        .onDeleteComentOfNote as Function
                                    )(c.idComent, this.getAllNoteComents);
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: GetIconFromType(
                                      ENU_ICON_TYPE.delete,
                                      13,
                                      "e1e1e1"
                                    ),
                                  }}
                                ></span>
                              )}
                          </span>
                          &nbsp;
                          {this.props.APP_GLOBAL_DATA.allNotifs.filter(
                            (notif) => notif.readed && notif.noteId === c.idComent
                          ).length > 0 && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: GetIconFromType(
                                    ENU_ICON_TYPE.notif,
                                    16,
                                    "f30000"
                                  ),
                                }}
                              ></span>
                            )}
                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: decodeUtf8((c.coment as any).data),
                          }}
                        ></div>
                      </div>
                    );
                  })}

              {
                // Nombre de commentaires
                !this.state.open && this.state.coments.length > 0 && (
                  <div
                    className="I_NOTE_COMENTS_COUNT"
                    onClick={() => (this.state.editMode ? {} : this.onOpenNote())}
                  >
                    <span>{this.state.coments.length}</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: GetIconFromType(ENU_ICON_TYPE.talk, 11, "3c3c3c"),
                      }}
                    ></span>
                  </div>
                )
              }
            </div>
            {/* ========================== */}
          </div>


        </div >
      );
    } catch (err) {
      return <div>
        <div>Oups!!! une erreur est survenue <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.error, 18) }}></span></div>
        <div>Ctrl + F5 pour raraichir la page....</div>
      </div>
    }

  }
}
